import React, { useState, useEffect } from 'react';
import { List, IconButton, InputBase, Divider } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import DescriptionIcon from '@mui/icons-material/Description';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import SearchIcon from '@mui/icons-material/Search';
import { fetchPastasPorCurso, fetchComponentesPorPagina } from '../services/api';
import MenuItemPastas from './MenuItemPastas';

interface Pagina {
  id: number;
  titulo: string;
  pastaId: number;
}

interface Pasta {
  id: number;
  nome: string;
  subPastas?: Pasta[];
  paginas?: Pagina[];
}

interface MenuSelecaoComponentesProps {
  onSelectComponent: (componentes: any[], pageId:number) => void; 
  isVisible: boolean;
  toggleVisibility: () => void;
  cursoId: number;
}

const MenuSelecaoComponentes: React.FC<MenuSelecaoComponentesProps> = ({ onSelectComponent, isVisible, toggleVisibility, cursoId }) => {
  const [pastas, setPastas] = useState<Pasta[]>([]);
  const [openItems, setOpenItems] = useState<{ [key: string]: boolean }>({});
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filteredResults, setFilteredResults] = useState<Pasta[]>([]);
  const [selectedPageId, setSelectedPageId] = useState<number | null>(null); // Estado para armazenar a página selecionada

  useEffect(() => {
    // Fetch pastas e páginas
    const fetchData = async () => {
      const pastaResponse = await fetchPastasPorCurso(cursoId);
      const pastasData: Pasta[] = pastaResponse.data;
      setPastas(pastasData);
    };
    fetchData();
  }, [cursoId]);

  // Função para expandir/colapsar pastas
  const handleMenuItemClick = (id: number, type: string) => {
    const newOpenItems = { ...openItems };
    newOpenItems[`${type}-${id}`] = !openItems[`${type}-${id}`];
    setOpenItems(newOpenItems);
  };

  // Função para selecionar uma página e buscar componentes
  const handleSelectPage = async (pageId: number | null) => {
    if (pageId) {
      setSelectedPageId(pageId); // Armazena o ID da página selecionada
      const response = await fetchComponentesPorPagina(pageId);
      onSelectComponent(response.data, pageId);
    } else {
      onSelectComponent([], null);
    }
  };

  // Função para filtrar resultados com base no termo de pesquisa
  useEffect(() => {
    if (searchTerm === '') {
      setFilteredResults([]);
      setOpenItems({}); // Fecha todas as pastas se não houver termo de pesquisa
    } else {
      const results: Pasta[] = [];
      const openItemsState: { [key: string]: boolean } = {};
      const searchLower = searchTerm.toLowerCase();

      const searchItems = (pastas: Pasta[], parentPath: string[] = []) => {
        return pastas.reduce((acc: Pasta[], pasta) => {
          let folderMatches = pasta.nome.toLowerCase().includes(searchLower);
          let paginasMatches = false;
          let subPastasMatches = false;

          const matchedPages = pasta.paginas?.filter((pagina) => 
            pagina.titulo.toLowerCase().includes(searchLower)
          ) || [];

          paginasMatches = matchedPages.length > 0;

          const matchedSubPastas = searchItems(pasta.subPastas || [], [...parentPath, pasta.nome]);
          subPastasMatches = matchedSubPastas.length > 0;

          if (folderMatches || paginasMatches || subPastasMatches) {
            // Expande a pasta correspondente
            openItemsState[`pasta-${pasta.id}`] = true; 
            // Adiciona subpastas e páginas encontradas
            acc.push({
              ...pasta,
              subPastas: matchedSubPastas.length > 0 ? pasta.subPastas : [],
              paginas: matchedPages.length > 0 ? matchedPages : [],
            });
          } else {
            // Expande a pasta se alguma subpasta foi expandida
            openItemsState[`pasta-${pasta.id}`] = acc.some((item) => item.id === pasta.id);
          }

          return acc;
        }, []);
      };

      const filtered = searchItems(pastas);
      setFilteredResults(filtered);
      setOpenItems((prevState) => ({ ...prevState, ...openItemsState }));
    }
  }, [searchTerm, pastas]);

  const renderFolder = (folder: Pasta, level: number = 0) => {
    const sortedSubPastas = folder.subPastas?.sort((a, b) => a.nome.localeCompare(b.nome)) || [];
    const sortedPaginas = folder.paginas?.sort((a, b) => a.titulo.localeCompare(b.titulo)) || [];

    return (
      <MenuItemPastas
        key={folder.id}
        name={folder.nome}
        id={folder.id}
        type="pasta"
        isOpen={openItems[`pasta-${folder.id}`]}
        onClick={() => handleMenuItemClick(folder.id, 'pasta')}
        icon={<FolderIcon />}
        level={level}
      >
        {openItems[`pasta-${folder.id}`] && (
          <>
            {/* Render subpastas */}
            {sortedSubPastas.length > 0 && sortedSubPastas.map((subFolder) => (
              <List key={subFolder.id} component="div" disablePadding>
                {renderFolder(subFolder, level + 1)}
              </List>
            ))}

            {/* Render páginas */}
            {sortedPaginas.length > 0 && sortedPaginas.map((page) => (
              <MenuItemPastas
                key={page.id}
                name={page.titulo}
                id={page.id}
                type="pagina"
                onClick={() => handleSelectPage(page.id)}
                icon={<DescriptionIcon />}
                level={level + 1}
                style={{
                  backgroundColor: selectedPageId === page.id ? '#e0f7fa' : 'transparent', // Azul claro para a página selecionada
                }}
              />
            ))}
          </>
        )}
      </MenuItemPastas>
    );
  };

  return (
    <div className={`menu-selecao-componentes-container ${isVisible ? '' : 'hidden'}`}>
      <div className="menu-selecao-componentes-header">
        <div>
          <IconButton onClick={toggleVisibility} color="primary" aria-label="toggle menu visibility">
            {isVisible ? <KeyboardDoubleArrowLeftIcon /> : <KeyboardDoubleArrowRightIcon />}
          </IconButton>
        </div>
      </div>

      {isVisible && (
        <>
          <div className="menu-search">
            <InputBase
              placeholder="Pesquisar..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              startAdornment={<SearchIcon />}
              fullWidth
            />
          </div>
          <Divider />
          <List>
            {searchTerm && filteredResults.length > 0 ? (
              filteredResults.map((folder) => renderFolder(folder, 0))
            ) : (
              pastas.map((folder) => renderFolder(folder))
            )}
          </List>
        </>
      )}
    </div>
  );
};

export default MenuSelecaoComponentes;
