import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from '@mui/material';
import CursosAdmin from './CursosAdmin';
import UsuariosAdmin from './UsuariosAdmin';

const AdminPage: React.FC = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
  };

  return ( 
    <div className="admin-page">
      <Tabs value={tabIndex} onChange={handleTabChange} aria-label="Admin Tabs">
        <Tab label="Cursos" />
        <Tab label="Usuários" />
      </Tabs>

      {tabIndex === 0 && <CursosAdmin />}
      {tabIndex === 1 && <UsuariosAdmin />}
    </div>
  );
};

export default AdminPage;
