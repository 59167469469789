import React, { useState, useRef, useEffect } from 'react';
import { Button, Box, Tab, Tabs, IconButton } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import JoditEditor from 'jodit-react';

interface ItensSelecaoEditorProps {
  question: string;
  correctItems: string[];
  incorrectItems: string[];
  comment?: string;
  onSave: (question: string, correctItems: string[], incorrectItems: string[], comment: string) => void;
  onCancel: () => void;
}

const ItensSelecaoEditor: React.FC<ItensSelecaoEditorProps> = ({
  question: initialQuestion,
  correctItems: initialCorrectItems,
  incorrectItems: initialIncorrectItems,
  comment: initialComment,
  onSave,
  onCancel,
}) => {
  const [question, setQuestion] = useState(initialQuestion);
  const [correctItems, setCorrectItems] = useState(initialCorrectItems);
  const [incorrectItems, setIncorrectItems] = useState(initialIncorrectItems);
  const [comment, setComment] = useState(initialComment || '');
  const [currentTab, setCurrentTab] = useState(0);
  const [focusCorrectIndex, setFocusCorrectIndex] = useState<number | null>(null);
  const [focusIncorrectIndex, setFocusIncorrectIndex] = useState<number | null>(null);
  const commentEditorRef = useRef<any>(null);
  const correctItemRefs = useRef<Map<number, HTMLInputElement | null>>(new Map());
  const incorrectItemRefs = useRef<Map<number, HTMLInputElement | null>>(new Map());

  useEffect(() => {
    if (focusCorrectIndex !== null) {
      const ref = correctItemRefs.current.get(focusCorrectIndex);
      if (ref) {
        ref.focus();
        setFocusCorrectIndex(null);
      }
    }
  }, [focusCorrectIndex]);

  useEffect(() => {
    if (focusIncorrectIndex !== null) {
      const ref = incorrectItemRefs.current.get(focusIncorrectIndex);
      if (ref) {
        ref.focus();
        setFocusIncorrectIndex(null);
      }
    }
  }, [focusIncorrectIndex]);

  const handleAddCorrectItem = () => {
    setCorrectItems([...correctItems, '']);
    setFocusCorrectIndex(correctItems.length); // Foca no novo campo
  };

  const handleAddIncorrectItem = () => {
    setIncorrectItems([...incorrectItems, '']);
    setFocusIncorrectIndex(incorrectItems.length); // Foca no novo campo
  };

  const handleItemChange = (
    items: string[],
    setItems: React.Dispatch<React.SetStateAction<string[]>>,
    index: number,
    value: string
  ) => {
    const updatedItems = [...items];
    updatedItems[index] = value;
    setItems(updatedItems);
  };

  const handleRemoveItem = (
    items: string[],
    setItems: React.Dispatch<React.SetStateAction<string[]>>,
    index: number
  ) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
  };

  const handleKeyPress = (e: React.KeyboardEvent, addItem: () => void) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      addItem();
    }
  };

  const handleSave = () => {
    const filteredCorrectItems = correctItems.filter((item) => item.trim() !== '');
    const filteredIncorrectItems = incorrectItems.filter((item) => item.trim() !== '');
    const currentComment = commentEditorRef.current?.value || comment;
    onSave(question, filteredCorrectItems, filteredIncorrectItems, currentComment);
  };

  const handleTabChange = (_event: React.ChangeEvent<{}>, newTab: number) => {
    if (currentTab === 1 && commentEditorRef.current) {
      setComment(commentEditorRef.current.value);
    }
    setCurrentTab(newTab);
  };

  const config = {
    readonly: false,
    toolbar: true,
    height: 400,
    autofocus: true,
    buttons: [
      'source', '|',
      'bold', 'italic', 'underline', 'strikethrough', '|',
      'superscript', 'subscript', '|',
      'ul', 'ol', '|',
      'outdent', 'indent', '|',
      'font', 'fontsize', 'brush', 'paragraph', '|',
      'image', 'video', 'table', 'link', '|',
      'align', 'undo', 'redo', '|',
      'hr', 'eraser', 'copyformat', 'symbols', '|',
      'fullsize', 'selectall', 'print', 'about', '|'
    ]
  };

  return (
    <div className="itens-selecao-editor">
      <h2>Editor de ItensSelecao</h2>
      <Tabs value={currentTab} onChange={handleTabChange}>
        <Tab label="Itens" />
        <Tab label="Comentário" />
      </Tabs>

      {currentTab === 0 && (
        <Box>
          <label>Pergunta:</label>
          <input
            type="text"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            style={{ width: '100%', padding: '8px', marginBottom: '16px' }}
          />

          <h3>Itens Corretos</h3>
          {correctItems.map((item, index) => (
            <Box display="flex" alignItems="center" key={index}>
              <input
                ref={(el) => correctItemRefs.current.set(index, el)}
                type="text"
                value={item}
                onChange={(e) => handleItemChange(correctItems, setCorrectItems, index, e.target.value)}
                onKeyPress={(e) => handleKeyPress(e, handleAddCorrectItem)}
                style={{ width: '100%', padding: '8px', marginBottom: '8px' }}
              />
              <IconButton onClick={() => handleRemoveItem(correctItems, setCorrectItems, index)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
          <Button onClick={handleAddCorrectItem}>Adicionar Item Correto</Button>

          <h3>Itens Incorretos</h3>
          {incorrectItems.map((item, index) => (
            <Box display="flex" alignItems="center" key={index}>
              <input
                ref={(el) => incorrectItemRefs.current.set(index, el)}
                type="text"
                value={item}
                onChange={(e) => handleItemChange(incorrectItems, setIncorrectItems, index, e.target.value)}
                onKeyPress={(e) => handleKeyPress(e, handleAddIncorrectItem)}
                style={{ width: '100%', padding: '8px', marginBottom: '8px' }}
              />
              <IconButton onClick={() => handleRemoveItem(incorrectItems, setIncorrectItems, index)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
          <Button onClick={handleAddIncorrectItem}>Adicionar Item Incorreto</Button>
        </Box>
      )}

      {currentTab === 1 && (
        <Box>
          <JoditEditor ref={commentEditorRef} value={comment} config={config} />
        </Box>
      )}

      <Button variant="contained" color="primary" onClick={handleSave}>
        Salvar Tudo
      </Button>
      <Button variant="contained" color="secondary" onClick={onCancel}>
        Cancelar
      </Button>
    </div>
  );
};

export default ItensSelecaoEditor;
