import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate, useParams } from 'react-router-dom';
import HomePage from './pages/HomePage';
import NovaPagina from './pages/NovaPagina';
import EstudoPersonalizado from './pages/EstudoPersonalizado';
import AlterarEmail from './pages/AlterarEmail';
import AlterarSenha from './pages/AlterarSenha';
import Header from './components/organisms/Header/Header';
import MenuPastas from './pages/MenuPastas';
import UsuarioPage from './pages/Pagina';
import LoginForm from './components/LoginForm';
import PrivateRoute from './components/PrivateRoute';
import { AuthProvider, useAuth } from './context/AuthContext';
import './App.css';
import './global.css';
import RevisaoEspacadaPage from './pages/RevisaoEspacadaPage';
import CursosPage from './pages/CursosPage'; // Página para listar cursos
import AdminPage from './pages/AdminPage';
import Home from './pages/Home';
import HomeLogado from './pages/HomeLogado';
import HomeDeslogado from './pages/HomeDeslogado';



declare global {
  interface Window {
    EXCALIDRAW_ASSET_PATH: string;
  }
}

window.EXCALIDRAW_ASSET_PATH = "/fonts";

// Componente para verificar se o usuário já está logado e redirecionar
const LoginRoute: React.FC = () => {
  const { user } = useAuth();

  if (user) {
    // Redireciona para a página inicial se o usuário já estiver logado
    return <Navigate to="/home" />;
  }
  else {
    return <LoginForm />;
  }
};

const AppContent: React.FC = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState<string>('home');
  const { cursoId } = useParams<{ cursoId: string }>();

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [user, navigate]);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const closeMenu = () => {
    if (menuVisible) {
      setMenuVisible(false);
    }
  };

  const handleSectionChange = (section: string) => {
    setActiveSection(section);
  };

  const handleSelectPage = (pageId: number) => {
    navigate(`/estudo-guiado/pagina/${pageId}`);
    closeMenu();
  };

  const toggleMenuVisibility = () => {
    setMenuVisible(!menuVisible);
  };

  return (
    <div className={`app-container ${menuVisible ? 'menu-visible' : 'menu-hidden'}`}>
      {user && (
        <>
          <Header
            toggleMenu={toggleMenu}
            closeMenu={closeMenu}
            handleSectionChange={handleSectionChange}
            activeSection={activeSection}
            toggleMenuPastas={toggleMenuVisibility}
          />
          {menuVisible && activeSection === 'estudo-personalizado' && (
            <MenuPastas
              onSelectPage={handleSelectPage}
              isVisible={menuVisible}
              toggleVisibility={toggleMenuVisibility}
              cursoId={Number(cursoId)}
              usuarioId={user.id || 0}
            />
          )}
        </>
      )}
      <div className="content" onClick={closeMenu}>
        <Routes>
          {/* Página de Login, redireciona para "/" se já logado */}
          <Route path="/login" element={<LoginRoute />} />
          <Route path="/home" element={<Home />} />
          {/* Rotas para usuários autenticados */}
          <Route element={<PrivateRoute allowedRoles={['free', 'pro', 'admin']} />}>
            <Route path="/desafios-do-dia" element={<div>Desafios do Dia</div>} />
            <Route path="/alterar-senha" element={<AlterarSenha />} />
            <Route path="/novo-pagina" element={<NovaPagina />} />
            <Route path="/estudo-personalizado" element={<CursosPage />} />
            {/* Página do curso com as pastas e páginas */}
            <Route path="/curso/:cursoId/*" element={<EstudoPersonalizadoWrapper />} />
            <Route path="/estudo-personalizado/:cursoId/*" element={<EstudoPersonalizadoWrapper />} />
            <Route path="/estudo-personalizado/minhas-notas/:pageId" element={<UsuarioPageWrapper />} />
            <Route path="/revisao-espacada/:cursoId" element={<RevisaoEspacadaPageWrapper />} />
          </Route>
          
          {/* Rotas para Admin */}
          <Route element={<PrivateRoute allowedRoles={['admin']} />}>
            <Route path="/admin" element={<AdminPage />} />
            <Route path="/novo-pagina" element={<NovaPagina />} />
          </Route>
        </Routes>
      </div>
    </div>
  );
};

// Wrapper para passar o cursoId para a EstudoPersonalizado
const EstudoPersonalizadoWrapper: React.FC = () => {
  const { cursoId } = useParams<{ cursoId: string }>();
  const { user } = useAuth();
  
  return (
    <EstudoPersonalizado userId={user?.id || 0} cursoId={Number(cursoId)} />
  );
};

// Wrapper para passar o pageId para a UsuarioPage
const UsuarioPageWrapper: React.FC = () => {
  const { pageId } = useParams<{ pageId: string }>();
  const { user } = useAuth();
  return <UsuarioPage pageId={Number(pageId)} usuarioId = {user?.id || 0} />;
};

// Wrapper para Revisão Espaçada
const RevisaoEspacadaPageWrapper: React.FC = () => {
  const { cursoId } = useParams<{ cursoId: string }>();
  const { user } = useAuth();
  
  return (
    <RevisaoEspacadaPage usuarioId={user?.id || 0} cursoId={Number(cursoId)} />
  );
};

const App: React.FC = () => {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
};

export default App;
