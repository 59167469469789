import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchCursos } from '../services/api';
import './CursosPage.css'; // Importando o arquivo de estilo
import logoMemoNow from './logo-memonow-nova.png'; // Importando a imagem

interface Curso {
  id: number;
  nome: string;
}

const CursosPage: React.FC = () => {
  const [cursos, setCursos] = useState<Curso[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchCursos(); 
      setCursos(response.data);
    };
    fetchData();
  }, []);

  const handleCursoClick = (cursoId: number) => {
    navigate(`/curso/${cursoId}`);
  };

  return (
    <div className="cursos-page">
      <h2 className="cursos-title">Meus Cursos</h2>
      <div className="cursos-container">
        {cursos.map((curso) => (
          <div key={curso.id} className="curso-card" onClick={() => handleCursoClick(curso.id)}>
            <div className='CursosPage-container-image'> 
              <img src={logoMemoNow} alt="Curso Logo" className="curso-card-image" />
            </div>
            <div className="curso-card-content">
              <p>{curso.nome}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CursosPage;
