import React, { useState, useEffect, useRef } from 'react';
import { parseContent } from '../../../helpers/parser';
import styled from 'styled-components';
import MenuIcon from '@mui/icons-material/Menu';
import './Texto.css';

export const Container = styled.div`
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  text-align: left;
  font-style: normal;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  color: #333;
  line-height: 1.8;

  p {
    margin: 20px 0;
    font-size: 18px;
  }

  b {
    color: #222;
    font-weight: bold;
  }

  a {
    color: #1e90ff;
    text-decoration: underline;
    &:hover {
      color: #104e8b;
    }
  }

  ul, ol {
    padding-left: 40px;
    margin-bottom: 20px;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 30px 0 10px;
    color: #333;
    font-weight: bold;
    scroll-margin-top: 60px;
  }


  @media (max-width: 768px) {
    h1, h2, h3, h4, h5, h6 {
      scroll-margin-top: 90px;
    }
  }

  blockquote {
    margin: 30px 0;
    padding: 20px;
    background-color: #f9f9f9;
    border-left: 5px solid #ccc;
    font-style: italic;
    color: #555;
  }

  img {
    max-width: 100%;
    height: auto;
    margin: 20px 0;
    border-radius: 8px;
  }

  pre {
    background-color: #f4f4f4;
    padding: 15px;
    border-radius: 8px;
    overflow-x: auto;
    font-size: 16px;
    line-height: 1.6;
  }
`;

const SummaryContainer = styled.div<{ expanded: boolean }>`
  position: fixed;
  top: 20px;
  right: ${({ expanded }) => (expanded ? '20px' : '-400px')};
  width: 350px;
  max-height: 80vh;
  overflow-y: auto;
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: right 0.3s ease-in-out;
  padding: 10px;
  z-index: 1000;
`;

const SummaryToggleButton = styled.button<{ isMobile: boolean; expanded: boolean }>`
  position: fixed;
  top: ${({ isMobile }) => (isMobile ? '50px' : '170px')};
  right: ${({ isMobile, expanded }) => (expanded ?  '375px' : (isMobile ? '0px' : '20px'))};
  background-color: #1e90ff;
  color: #fff;
  padding: ${({ isMobile }) => (isMobile ? '6px' : '6px')};
  border: none;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1001;
  transition: right 0.3s ease-in-out, top 0.3s ease-in-out, padding 0.3s ease-in-out;

  &:hover {
    background-color: #104e8b;
  }
`;

const SummaryList = styled.ul`
  list-style-type: none;
  padding-left: 0;

  li {
    margin: 5px 0;
  }

  a {
    color: #1e90ff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 12px;
    color: #1e90ff;
  }
`;

interface SummaryItem {
  id: string;
  level: number;
  text: string;
  isCollapsed: boolean;
}

interface TextoProps {
  html: string;
}

const Texto: React.FC<TextoProps> = ({ html }) => {
  const [content, setContent] = useState<React.ReactNode[]>([]);
  const [showSummary, setShowSummary] = useState(false);
  const [summary, setSummary] = useState<SummaryItem[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const parseHtmlContent = async () => {
      const parsedContent = await parseContent(html);
      setContent(parsedContent);
    };

    parseHtmlContent();
  }, [html]);

  useEffect(() => {
    if (containerRef.current) {
      const headers = Array.from(containerRef.current.querySelectorAll('h1, h2, h3, h4, h5, h6'));
      if (headers.length > 0) {
        headers.forEach((header, index) => {
          const id = `header-${index}`;
          header.setAttribute('id', id);
        });
        generateSummary(headers);
      } else {
        setSummary([]); // Limpa o sumário se não houver cabeçalhos
      }
    }
  }, [content]);

  const toggleSummary = () => {
    setShowSummary(!showSummary);
  };

  const toggleCollapse = (index: number) => {
    setSummary((prevSummary) =>
      prevSummary.map((item, idx) => {
        if (idx === index) {
          return { ...item, isCollapsed: !item.isCollapsed };
        }
        return item;
      })
    );
  };

  const generateSummary = (headers: Element[]) => {
    const summaryItems = headers.map((header, index) => {
      const id = `header-${index}`;
      const level = parseInt(header.tagName.replace('H', ''), 10);
      const headerText = header.textContent || `Header ${index}`;

      return {
        id,
        level,
        text: headerText,
        isCollapsed: false
      };
    });

    setSummary(summaryItems);
  };

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, id: string) => {
    e.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setShowSummary(false);
  };

  // Função para verificar se há subtítulos (nível abaixo)
  const hasSubtitles = (items: SummaryItem[], currentLevel: number, currentIndex: number): boolean => {
    for (let i = currentIndex + 1; i < items.length; i++) {
      if (items[i].level > currentLevel) {
        return true; // Há subtítulos
      }
      if (items[i].level <= currentLevel) {
        return false; // Chegou a outro item no mesmo nível ou acima
      }
    }
    return false;
  };

  const renderSummary = (items: SummaryItem[], parentLevel: number, startIndex: number = 0): React.ReactNode => {
    let renderedItems = [];

    for (let i = startIndex; i < items.length; i++) {
      const item = items[i];

      if (item.level === parentLevel + 1) {
        const hasSub = hasSubtitles(items, item.level, i);

        renderedItems.push(
          <li key={item.id} style={{ paddingLeft: `25px` }}>
            <div className='summary-item'>
              <div className='summary-button-container'>
              {hasSub ? (
                <button onClick={() => toggleCollapse(i)}>{item.isCollapsed ? '➕' : '➖'}</button>
              ) : (
                <span className="placeholder-button"></span> /* Adiciona um espaço vazio */
              )}
              </div>
              <div className='summary-link-container'>
              <a href={`#${item.id}`} onClick={(e) => handleLinkClick(e, item.id)}>
                {item.text}
              </a>
              </div>
            </div>
            {!item.isCollapsed && renderSummary(items, item.level, i + 1)}
          </li>
        );
      } else if (item.level <= parentLevel) {
        break; // Sai do loop quando o nível atual é menor ou igual ao nível do pai
      }
    }

    return <>{renderedItems}</>;
  };

  return (
    <div>
      {summary.length > 0 && (
        <>
          <SummaryToggleButton onClick={toggleSummary} expanded={showSummary ? true : undefined} isMobile={isMobile}>
            <MenuIcon />
          </SummaryToggleButton>
          <SummaryContainer className='summary-container' expanded={showSummary}>
            <SummaryList>{renderSummary(summary, 0)}</SummaryList>
          </SummaryContainer>
        </>
      )}
      <Container ref={containerRef}>{content}</Container>
    </div>
  );
};

export default Texto;
