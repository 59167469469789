import React, { useState, useEffect, useCallback } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd';
import { FaCheck, FaEye, FaEraser, FaTrashAlt, FaArrowRight, FaArrowDown, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { AiOutlineCheck, AiOutlineClose, AiOutlineWarning } from 'react-icons/ai';
import './PreencherItemFases.css';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { Replay } from '@mui/icons-material';

interface Phase {
  name: string;
  order: number;
  items: string[];
}

interface PreencherItemFasesProps {
  data: {
    title?: string;
    phases: Phase[];
    considerarOrdem?: boolean;
    comment?: string;
  };
}

const PreencherItemFases: React.FC<PreencherItemFasesProps> = ({ data }) => {
  const [phases, setPhases] = useState<Phase[]>([]);
  const [inputValues, setInputValues] = useState<{ [key: string]: string[] }>({});
  const [result, setResult] = useState<{ phaseResults: boolean[], itemResults: { [key: string]: string[] } }>({
    phaseResults: [],
    itemResults: {},
  });
  const [resultString, setResultString] = useState('');
  const [hasEvaluated, setHasEvaluated] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600); // Estado para controlar o layout
  const [showComment, setShowComment] = useState(false);

  const shuffle = useCallback((array: any[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }, []);

  useEffect(() => {
    if (data) {
      setPhases(shuffle([...data.phases]));
      const initialInputValues: { [key: string]: string[] } = {};
      data.phases.forEach(phase => {
        initialInputValues[phase.name] = phase.items.map(() => '');
      });
      setInputValues(initialInputValues);
    }
  }, [data, shuffle]);

  // Efeito para atualizar o estado de isMobile com base no redimensionamento da tela
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
      return;
    }

    const reorderedPhases = Array.from(phases);
    const [movedPhase] = reorderedPhases.splice(source.index, 1);
    reorderedPhases.splice(destination.index, 0, movedPhase);

    setPhases(reorderedPhases);
  };

  const normalizeString = (str: string) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
  };

  const handleInputChange = (phaseName: string, index: number, value: string) => {
    setInputValues(prev => ({
      ...prev,
      [phaseName]: prev[phaseName].map((item, i) => (i === index ? value : item)),
    }));
  };

  const evaluateItems = () => {
    const phaseResults: boolean[] = [];
    const itemResults: { [key: string]: string[] } = {};
    let correctPhaseCount = 0;
    let correctItemCount = 0;
    let totalItemCount = 0;
    const usedItems = new Set<string>(); // Rastreador de itens/sinônimos já usados
  
    phases.forEach((phase, index) => {
      const originalPhase = data.phases[index];
      const isCorrectPhaseOrder = originalPhase && phase.name === originalPhase.name;
      phaseResults.push(isCorrectPhaseOrder);
      if (isCorrectPhaseOrder) {
        correctPhaseCount++;
      }
    });
  
    phases.forEach(phase => {
      const correctPhase = data.phases.find(p => p.name === phase.name);
      
      // Cria um array de arrays, onde cada subarray contém os sinônimos de um item
      const correctItemsWithSynonyms = correctPhase?.items.map(item =>
        item.split('/').map(synonym => normalizeString(synonym))
      ) || [];
  
      const itemEvaluation = inputValues[phase.name].map((userItem, index) => {
        totalItemCount++;
        const normalizedUserItem = normalizeString(userItem);
        let isCorrect = false;
  
        // Verifica cada array de sinônimos para ver se o item inserido é válido
        correctItemsWithSynonyms.some(synonymArray => {
          if (synonymArray.includes(normalizedUserItem) && !usedItems.has(synonymArray[0])) {
            isCorrect = true;
            // Marca todos os sinônimos do item como utilizados
            synonymArray.forEach(synonym => usedItems.add(synonym));
            return true; // Interrompe a iteração após encontrar um item correto
          }
          return false;
        });
  
        if (isCorrect) {
          if (data.considerarOrdem) {
            // Se considerar a ordem, verifica também o índice
            const correctIndexArray = correctItemsWithSynonyms[index];
            if (correctIndexArray && correctIndexArray.includes(normalizedUserItem)) {
              correctItemCount++;
              return 'correct';
            } else {
              return 'wrong-order';
            }
          } else {
            correctItemCount++;
            return 'correct';
          }
        } else {
          return 'incorrect';
        }
      });
  
      itemResults[phase.name] = itemEvaluation;
    });
  
    const totalPhases = phases.length;
    const totalItems = totalItemCount;
    const phaseScore = (correctPhaseCount / totalPhases) * 100;
    const itemScore = (correctItemCount / totalItems) * 100;
  
    setResult({ phaseResults, itemResults });
    setResultString(`Fases: ${phaseScore.toFixed(2)}% corretas, Itens: ${itemScore.toFixed(2)}% corretos`);
    setHasEvaluated(true);
  };
  

  const showAnswers = () => {
    const correctAnswers: { [key: string]: string[] } = {};
    data.phases.forEach(phase => {
      correctAnswers[phase.name] = [...phase.items];
    });
    setInputValues(correctAnswers);
  };

  const clearFields = () => {
    // Embaralha novamente as fases
    const shuffledPhases = shuffle([...data.phases]);
    setPhases(shuffledPhases);  // Atualiza as fases com a nova ordem embaralhada
  
    const clearedValues: { [key: string]: string[] } = {};
    shuffledPhases.forEach(phase => {
      clearedValues[phase.name] = phase.items.map(() => '');
    });
  
    setInputValues(clearedValues);
    setResult({ phaseResults: [], itemResults: {} });
    setHasEvaluated(false);
    setResultString('');
  };
  

  const removeIncorrects = () => {
    const newValues: { [key: string]: string[] } = {};
    phases.forEach(phase => {
      newValues[phase.name] = inputValues[phase.name].map((item, index) => {
        return result.itemResults[phase.name]?.[index] === 'incorrect' ? '' : item;
      });
    });
    setInputValues(newValues);
    setHasEvaluated(false);
  };

  const adjustTextareaHeight = (el: HTMLTextAreaElement | null) => {
    if (el) {
      el.style.height = '10px'; // Redefine a altura para calcular o novo conteúdo
      el.style.height = `${el.scrollHeight}px`; // Ajusta a altura de acordo com o conteúdo
    }
  };

  const toggleComment = () => setShowComment(!showComment);


  return (
    <div className="preencher-item-fases-container">
      {data.title && <h3>{data.title}</h3>}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="phases" direction={isMobile ? "vertical" : "horizontal"} type="PHASE">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps} className="preencher-item-fases-phases-container">
              {phases.map((phase, index) => (
                <div key={phase.name} className="preencher-item-fases-phase-container">
                  <Draggable key={phase.name} draggableId={phase.name} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`preencher-item-fases-phase ${
                          hasEvaluated ? (result.phaseResults[index] ? 'correct-phase' : 'incorrect-phase') : ''
                        }`}
                      >
                        <h4>{phase.name}</h4>
                        <ul>
                          {phase.items.map((item, itemIndex) => {
                            const itemStatus = result.itemResults[phase.name]?.[itemIndex];
                            let itemClass = '';
                            let icon = null;

                            if (itemStatus === 'correct') {
                              itemClass = 'correct-item';
                              icon = <AiOutlineCheck />;
                            } else if (itemStatus === 'incorrect') {
                              itemClass = 'incorrect-item';
                              icon = <AiOutlineClose />;
                            } else if (itemStatus === 'wrong-order') {
                              itemClass = 'wrong-order-item';
                              icon = <AiOutlineWarning />;
                            }

                            return (
                              <li key={itemIndex} className={itemClass}>
                                <textarea
                                  value={inputValues[phase.name][itemIndex]}
                                  onChange={(e) => handleInputChange(phase.name, itemIndex, e.target.value)}
                                  rows={1}
                                  className={`preencher-item-fases-textarea-${itemClass}`}
                                  style={{ resize: 'none', overflow: 'hidden'}} // Desativa a rolagem e o redimensionamento manual
                                  ref={(el) => adjustTextareaHeight(el)} 
                                />
                                {icon}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}
                  </Draggable>
                  {!isMobile && index < phases.length - 1 && <FaArrowRight className="preencher-item-fases-arrow" />}
                  {isMobile && index < phases.length - 1 && <FaArrowDown className="preencher-item-fases-arrow" />}
                </div>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className="preencher-item-fases-buttons">
        <button className='preencher-item-fases-button btnAvaliar' onClick={evaluateItems}><FaCheck size={24} /> <span>Avaliar</span></button>
        <button className='preencher-item-fases-button btnMostrarRespostas' onClick={showAnswers}><FaEye size={24} /> <span>Mostrar Respostas</span></button>
        <button className='preencher-item-fases-button btnRemoverIncorretos' onClick={removeIncorrects}><FaEraser size={24} /> <span>Remover Incorretos</span></button>
        <button className='preencher-item-fases-button btnLimpar' onClick={clearFields}><Replay /> <span>Reiniciar</span></button>        
      </div>
      <div className="preencher-item-fases-result">{resultString}</div>
      {data.comment &&  data.comment !== '<p><br></p>' && (
        <>
          <div className="accordion" onClick={toggleComment}>
            <span>{showComment ? 'Ocultar Comentário' : 'Saiba mais'}</span>
            {showComment ? <FaChevronUp /> : <FaChevronDown />} 
          </div>
          {showComment && <div className="preencher-item-fases-comment" dangerouslySetInnerHTML={{ __html: data.comment }}/>}
        </>
      )}
    </div>
  );
};

export default PreencherItemFases;
