import React from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';
import AlertIcon from '@mui/icons-material/Warning';
import QuestionIcon from '@mui/icons-material/Help';
import InfoIcon from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';
import ImportantIcon from '@mui/icons-material/Star';
import CancelIcon from '@mui/icons-material/Cancel';
import BalanceOutlinedIcon from '@mui/icons-material/BalanceOutlined';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import GavelOutlinedIcon from '@mui/icons-material/GavelOutlined';
import WysiwygOutlinedIcon from '@mui/icons-material/WysiwygOutlined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookBookmark, faSection } from '@fortawesome/free-solid-svg-icons';
import './Comment.css';

const CommentContainer = styled.div<{ bgColor: string }>`
  background-color: ${({ bgColor }) => bgColor};
  padding: 0;
  margin: 0;
  border-radius: 4px;
  border-left: 6px solid ${({ bgColor }) => bgColor};
`;

const CommentTitle = styled.div`
  font-weight: bold;
  margin-left: 8px;
`;

interface CommentProps {
  type?: string;
  color?: string;
  title: string;
  text: string;
}

const getIcon = (type: string) => {
  switch (type) {
    case 'Alerta':
      return <AlertIcon />;
    case 'Questão':
      return <HelpCenterOutlinedIcon />;
    case 'Informação':
      return <InfoIcon />;
    case 'Erro':
      return <CancelIcon />;
    case 'Importante':
      return <ImportantIcon />;
    case 'Lei':
      return <FontAwesomeIcon className='icones-secoes' icon={faSection} />;
    case 'Jurisprudencia':
      return <BalanceOutlinedIcon />;
    default:
      return null;
  }
};

const getBackgroundColor = (type: string) => {
  switch (type) {
    case 'Alerta':
      return '#fff3cd';
    case 'Questão':
      return '#fcfcf5';
    case 'Informação':
      return '#d4edda';
    case 'Erro':
      return '#f8d7da';
    case 'Importante':
      return '#fff8e1';
    case 'Jurisprudencia':
      return '#dcedf7';
    case 'Lei':
      return '#ebeae8'
    default:
      return 'yellow'; // Default background color for generic comments
  }
};

const Comment: React.FC<CommentProps> = ({ type, color, title, text }) => {
  const bgColor = type ? getBackgroundColor(type) : color || 'yellow';
  const icon = type ? getIcon(type) : null;

  return (
    <CommentContainer bgColor={bgColor} className='comentario-container'>
      <div className={`comentario-container-${type}`} style={{ display: 'flex', alignItems: 'center', color: color }}>
        {icon}
        <CommentTitle>{parse(title)}</CommentTitle>
      </div>
      <div className='comentario-texto'>{parse(text)}</div>
    </CommentContainer>
  );
};

export default Comment;
