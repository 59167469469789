import React, { useState, useEffect, useRef } from 'react';
import { Button, IconButton, TextField, Tab, Tabs, Box } from '@mui/material';
import { ArrowUpward, ArrowDownward, Delete, Add } from '@mui/icons-material';
import JoditEditor from 'jodit-react';
import './OrdenacaoEditor.css';

interface Stage {
  order: number;
  value: string;
}

interface OrdenacaoEditorProps {
  initialStages: Stage[];
  question: string;
  comment?: string;
  onSave: (question: string, stages: Stage[], comment:string) => void;
  onCancel: () => void;
}

const OrdenacaoEditor: React.FC<OrdenacaoEditorProps> = ({ initialStages, question: initialQuestion, comment: initialComment = '', onSave, onCancel }) => {
  const [stages, setStages] = useState<Stage[]>(initialStages);
  const [question, setQuestion] = useState<string>(initialQuestion);
  const [comment, setComment] = useState<string>(initialComment); // Estado para o comentário
  const [currentTab, setCurrentTab] = useState(0); // Aba atual
  const [isValid, setIsValid] = useState<boolean>(true);
  const [isSaveEnabled, setIsSaveEnabled] = useState<boolean>(false);
  const inputsRef = useRef<HTMLInputElement[]>([]);
  const commentEditorRef = useRef<any>(null);

  useEffect(() => {
    inputsRef.current = inputsRef.current.slice(0, stages.length);
    validateStages();
  }, [stages]);

  const validateStages = () => {
    const allStagesFilled = stages.every(stage => stage.value.trim() !== '');
    setIsValid(allStagesFilled);
    setIsSaveEnabled(allStagesFilled && stages.length >= 2);
  };

  const handleTabChange = (_event: React.ChangeEvent<{}>, newTab: number) => {
    if (currentTab === 1 && commentEditorRef.current) {
      // Atualiza o estado do comentário ao sair da aba de comentário
      setComment(commentEditorRef.current.value);
    }
    setCurrentTab(newTab);
  };

  const handleAddStage = () => {
    const newStage = { order: stages.length + 1, value: '' };
    setStages([...stages, newStage]);
  };

  const handleDeleteStage = (index: number) => {
    const newStages = stages.filter((_, i) => i !== index).map((stage, i) => ({ ...stage, order: i + 1 }));
    setStages(newStages);
  };

  const handleMoveStage = (index: number, direction: 'up' | 'down') => {
    const newStages = [...stages];
    const [removedStage] = newStages.splice(index, 1);
    newStages.splice(direction === 'up' ? index - 1 : index + 1, 0, removedStage);
    setStages(newStages.map((stage, i) => ({ ...stage, order: i + 1 })));
  };

  const handleStageChange = (index: number, value: string) => {
    const newStages = stages.map((stage, i) => (i === index ? { ...stage, value } : stage));
    setStages(newStages);
    validateStages();

    if (value && value.slice(-1) === '\n') {
      handleAddStage();
      setTimeout(() => {
        if (inputsRef.current[index + 1]) {
          inputsRef.current[index + 1].focus();
        }
      }, 0);
    }
  };

  const handleSave = () => {
    if (isSaveEnabled) {
      const currentComment = commentEditorRef.current?.value || comment;
      onSave(question, stages, currentComment);
    }
  };

  const config = {
    readonly: false,
    toolbar: true,
    height: 300,
    autofocus: true,
    buttons: ['bold', 'italic', 'underline', '|', 'ul', 'ol', '|', 'align', 'undo', 'redo', '|', 'image', 'link'],
  };

  return (
    <div className="ordenacao-editor">
      <Tabs value={currentTab} onChange={handleTabChange}>
        <Tab label="Etapas" />
        <Tab label="Comentário" />
      </Tabs>

      {currentTab === 0 && (
        <Box>
          <TextField
            label="Pergunta"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            fullWidth
            margin="normal"
          />
          <div className="ordenacao-editor-stages">
            {stages.map((stage, index) => (
              <div key={index} className="ordenacao-editor-stage">
                <TextField
                  inputRef={(el) => inputsRef.current[index] = el}
                  label={`Etapa ${index + 1}`}
                  value={stage.value}
                  onChange={(e) => handleStageChange(index, e.target.value)}
                  fullWidth
                  margin="normal"
                  error={!isValid && stage.value.trim() === ''}
                  helperText={!isValid && stage.value.trim() === '' ? 'Este campo é obrigatório' : ''}
                />
                <div className="ordenacao-editor-stage-controls">
                  <IconButton
                    onClick={() => handleMoveStage(index, 'up')}
                    disabled={index === 0}
                    size="small"
                  >
                    <ArrowUpward />
                  </IconButton>
                  <IconButton
                    onClick={() => handleMoveStage(index, 'down')}
                    disabled={index === stages.length - 1}
                    size="small"
                  >
                    <ArrowDownward />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteStage(index)} size="small">
                    <Delete />
                  </IconButton>
                </div>
              </div>
            ))}
          </div>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={handleAddStage}
            fullWidth
            style={{ marginBottom: '1rem' }}
          >
            Adicionar Etapa
          </Button>
        </Box>
      )}

      {currentTab === 1 && (
        <Box>
          <JoditEditor ref={commentEditorRef} value={comment} config={config} />
        </Box>
      )}

      <div className="ordenacao-editor-actions">
        <Button variant="contained" color="secondary" onClick={onCancel}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={!isSaveEnabled}
        >
          Salvar
        </Button>
      </div>
    </div>
  );
};

export default OrdenacaoEditor;
