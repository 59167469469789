import React from 'react';
import HomeDeslogado from './HomeDeslogado';
import HomeLogado from './HomeLogado';
import { AuthProvider, useAuth } from '../context/AuthContext';

// Simulação de estado de login (substitua por lógica real de autenticação)

const IsLoggedIn = (): boolean => {
    const { user } = useAuth();
    if (user) {
      // Retorna true se o usuário estiver logado
      return true;
    } else {
      // Retorna false se o usuário não estiver logado
      return false;
    }
  };
  

const Home: React.FC = () => {
  return IsLoggedIn ? <HomeLogado /> : <HomeDeslogado />;
};

export default Home;
