import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faWrench, faBookOpenReader, faBullseye, faUser, faSignOutAlt, faEnvelope, faKey } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../../context/AuthContext';

interface HeaderProps {
  toggleMenu: () => void;
  closeMenu: () => void;
  handleSectionChange: (section: string) => void;
  activeSection: string;
  toggleMenuPastas: () => void; // Adicionado
}

const Header: React.FC<HeaderProps> = ({ toggleMenu, closeMenu, handleSectionChange, activeSection, toggleMenuPastas }) => {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [userMenuVisible, setUserMenuVisible] = useState<boolean>(false);
  const navigate = useNavigate();
  const { user, logout } = useAuth();

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Fecha o menu do usuário se clicar fora
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest('.user-menu-container') && userMenuVisible) {
        setUserMenuVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [userMenuVisible]);

  const changeSection = (section: string) => {
    handleSectionChange(section);
    closeMenu();
    if (section === 'home') {
      navigate('/home');
    } else {
      navigate(`/${section}`);
    }
  };

  const toggleUserMenu = () => {
    setUserMenuVisible(!userMenuVisible);
  };

  return (
    <header className="header">
      
      <div className='header-botoes-esquerda'>
        <button className="home-button" onClick={() => changeSection('home')}>
          <FontAwesomeIcon icon={faHome} />
        </button>
      </div>

      <div className="section-switcher">
        {user && (
          <button
            className={`section-button ${activeSection === 'estudo-personalizado' ? 'active' : ''}`}
            onClick={() => changeSection('estudo-personalizado')}
          >
            <FontAwesomeIcon className='icones-secoes' icon={faBookOpenReader} /> {windowWidth > 768 && 'Meus Cursos'}
          </button>
        )}
        {user && false && (
        <button
          className={`section-button ${activeSection === 'desafios-do-dia' ? 'active' : ''}`}
          onClick={() => changeSection('desafios-do-dia')}
        >
          <FontAwesomeIcon className='icones-secoes' icon={faBullseye} /> {windowWidth > 768 && 'Desafios do Dia'}
        </button>
        )}
      </div>

      <div className="header-right">
        {user && (
          <div className="user-menu-container">
            <button className="user-menu-button" onClick={toggleUserMenu}>
              <FontAwesomeIcon icon={faUser} />
            </button>
            {userMenuVisible && (
              <div className="user-menu">
                <button className='botao-alterar-email' onClick={() => navigate('/alterar-email')}>
                  <FontAwesomeIcon icon={faEnvelope} /> Alterar Email
                </button>
                <button onClick={() => navigate('/alterar-senha')}>
                  <FontAwesomeIcon icon={faKey} /> Alterar Senha
                </button>
                <button onClick={logout}>
                  <FontAwesomeIcon icon={faSignOutAlt} /> Sair
                </button>
              </div>
            )}
          </div>
        )}
      </div>

      {user?.tipo === 'admin' && (
        <button className="admin-button" onClick={() => changeSection('admin')}>
          <FontAwesomeIcon icon={faWrench} />
        </button>
      )}
    </header>
  );
};

export default Header;
