import React from 'react';
import { Button, Accordion, AccordionSummary, AccordionDetails, Typography, Grid, Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import './HomeLogado.css';

const HomeLogado: React.FC = () => {
  return (
    <div className="home-logado-container">
      {/* Cabeçalho da página */}
      <header className="home-header">
        <Typography variant="h2" className="home-title">
          Bem-vindo de volta ao MemoNow
        </Typography>
        <Typography variant="h6" className="home-subtitle">
          Torne-se um assinante premium e eleve seu aprendizado a um novo patamar.
        </Typography>
        <Button variant="contained" className="home-subscribe-button">
          Assine Agora
        </Button>
      </header>

      {/* Vantagens */}
      <section className="home-section">
        <Typography variant="h4" gutterBottom>
          Vantagens de Criar Seus Próprios Recursos
        </Typography>
        <List>
          <ListItem>
            <ListItemIcon>
              <CheckCircleOutlineIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Crie e personalize flashcards para revisar conteúdos de forma dinâmica." />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleOutlineIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Utilize ocultação de imagens para treinar informações visuais." />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleOutlineIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Elabore resumos claros e diagramas para organizar ideias." />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleOutlineIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Desenvolva exercícios como completar texto, ordenar itens ou categorizar informações." />
          </ListItem>
        </List>
      </section>

      {/* Ferramentas Premium */}
      <section className="home-section">
        <Typography variant="h4" gutterBottom>
          Ferramentas Premium
        </Typography>
        <Typography>
          Ao assinar, você desbloqueia recursos avançados, como estatísticas detalhadas, maior capacidade de armazenamento 
          e acesso a ferramentas como fases, quadros e diagramas interativos.
        </Typography>
      </section>

      {/* Por que assinar? */}
      <section className="home-section">
        <Typography variant="h4" gutterBottom>
          Por que Assinar?
        </Typography>
        <List>
          <ListItem>
            <ListItemIcon>
              <CheckCircleOutlineIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary="Acesso ilimitado a todos os recursos, cursos premium e relatórios personalizados." />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleOutlineIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary="Crie um ambiente de estudo totalmente adaptado às suas necessidades." />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleOutlineIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary="Aproveite suporte prioritário e dicas exclusivas para melhorar sua preparação." />
          </ListItem>
        </List>
      </section>

      {/* Accordion com perguntas frequentes */}
      <section className="home-section">
        <Typography variant="h4" gutterBottom>
          Perguntas Frequentes
        </Typography>
        <Box className="faq-container">
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="faq1-content" id="faq1-header">
              <HelpOutlineIcon color="primary" sx={{ marginRight: 1 }} />
              <Typography><strong>O que é o MemoNow?</strong></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                O MemoNow é uma plataforma inovadora de aprendizado que combina técnicas comprovadas de memorização,
                como flashcards e revisão espaçada, para ajudar você a alcançar seus objetivos de estudo.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="faq2-content" id="faq2-header">
              <HelpOutlineIcon color="primary" sx={{ marginRight: 1 }} />
              <Typography><strong>Como funcionam os recursos premium?</strong></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Os recursos premium incluem ferramentas avançadas, como análises detalhadas de desempenho, maior espaço de armazenamento,
                acesso a cursos exclusivos e suporte prioritário.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="faq3-content" id="faq3-header">
              <HelpOutlineIcon color="primary" sx={{ marginRight: 1 }} />
              <Typography><strong>Posso experimentar antes de assinar?</strong></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Sim! Você pode experimentar a versão gratuita do MemoNow, que já oferece várias funcionalidades incríveis. Para desbloquear o máximo potencial,
                basta se tornar um assinante premium.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </section>
    </div>
  );
};

export default HomeLogado;
