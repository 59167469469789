import React, { useRef, useState, useEffect } from 'react';
import JoditEditor from 'jodit-react';
import { Button, ToggleButton, ToggleButtonGroup, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import './CardEditor.css';

interface CardEditorProps {
  initialFront: string;
  initialBack: string;
  initialMode?: 'flip' | 'reveal';
  initialComment?: string;
  onSave: (front: string, back: string, mode: 'flip' | 'reveal', comment: string, action: 'close' | 'addNew') => void;
  onCancel: () => void;
  isNew: boolean; // Adicionada nova prop
}

const CardEditor: React.FC<CardEditorProps> = ({ initialFront, initialBack, initialMode = 'reveal', initialComment = '', onSave, onCancel, isNew }) => {
  const frontEditorRef = useRef<any>(null);
  const backEditorRef = useRef<any>(null);
  const commentEditorRef = useRef<any>(null);

  const [currentTab, setCurrentTab] = useState<'front' | 'back' | 'comment'>('front');
  const [frontContent, setFrontContent] = useState(initialFront);
  const [backContent, setBackContent] = useState(initialBack);
  const [commentContent, setCommentContent] = useState(initialComment);
  const [mode, setMode] = useState<'flip' | 'reveal'>(initialMode);

  useEffect(() => {
    setMode(initialMode || 'reveal');
  }, [initialMode]);

  useEffect(() => {
    if (currentTab === 'front' && frontEditorRef.current) {
      setTimeout(() => {
        frontEditorRef.current?.editor?.focus();
      }, 1000);
    }
  }, [currentTab]);

  const handleSave = (action: 'close' | 'addNew') => {
    const currentFrontContent = frontEditorRef.current?.value || frontContent;
    const currentBackContent = backEditorRef.current?.value || backContent;
    const currentCommentContent = commentEditorRef.current ? commentEditorRef.current.value : commentContent;

    onSave(currentFrontContent, currentBackContent, mode, currentCommentContent, action);
    
    if (action === 'addNew') {
      setFrontContent('');
      setBackContent('');
      setCommentContent('');
      setCurrentTab('front');
      // Limpa os refs para evitar reutilização de valores anteriores
      backEditorRef.current = null;
      commentEditorRef.current = null;
    }
  };

  const handleTabChange = (_event: React.MouseEvent<HTMLElement>, newTab: 'front' | 'back' | 'comment') => {
    if (newTab) {
      if (currentTab === 'front') {
        setFrontContent(frontEditorRef.current?.value || '');
      } else if (currentTab === 'back') {
        setBackContent(backEditorRef.current?.value || '');
      } else if (currentTab === 'comment') {
        setCommentContent(commentEditorRef.current?.value || '');
      }
      setCurrentTab(newTab);
    }
  };

  const handleModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMode(event.target.value as 'flip' | 'reveal');
  };

  const config = {
    readonly: false,
    toolbar: true,
    height: 400,
    autofocus: true,
    buttons: [
      'source', '|',
      'bold', 'italic', 'underline', 'strikethrough', '|',
      'superscript', 'subscript', '|',
      'ul', 'ol', '|',
      'outdent', 'indent', '|',
      'font', 'fontsize', 'brush', 'paragraph', '|',
      'image', 'video', 'table', 'link', '|',
      'align', 'undo', 'redo', '|',
      'hr', 'eraser', 'copyformat', 'symbols', '|',
      'fullsize', 'selectall', 'print', 'about', '|'
    ]
  };

  return (
    <div className="CardEditor-container">
      <ToggleButtonGroup
        value={currentTab}
        exclusive
        onChange={handleTabChange}
        aria-label="text editor tabs"
      >
        <ToggleButton value="front" aria-label="front">
          Frente
        </ToggleButton>
        <ToggleButton value="back" aria-label="back">
          Verso
        </ToggleButton>
        {mode === 'reveal' && (
          <ToggleButton value="comment" aria-label="comment">
            Comentário
          </ToggleButton>
        )}
      </ToggleButtonGroup>
      <div className="CardEditor-editor">
        {currentTab === 'front' && (
          <JoditEditor
            ref={frontEditorRef}
            value={frontContent}
            config={config}
          />
        )}
        {currentTab === 'back' && (
          <JoditEditor
            ref={backEditorRef}
            value={backContent}
            config={config}
          />
        )}
        {currentTab === 'comment' && mode === 'reveal' && (
          <JoditEditor
            ref={commentEditorRef}
            value={commentContent}
            config={config}
          />
        )}
      </div>
      <FormControl component="fieldset">
        <FormLabel component="legend">Modo</FormLabel>
        <RadioGroup row aria-label="mode" name="mode" value={mode} onChange={handleModeChange}>
          <FormControlLabel value="flip" control={<Radio />} label="Virar" />
          <FormControlLabel value="reveal" control={<Radio />} label="Revelar embaixo" />
        </RadioGroup>
      </FormControl>
      <div className="CardEditor-buttons">
        {isNew ? (
          <>
            <Button variant="contained" color="primary" onClick={() => handleSave('addNew')}>
              Adicionar
            </Button>
            <Button variant="contained" onClick={onCancel}>
              Sair
            </Button>
          </>
        ) : (
          <>
            <Button variant="contained" color="primary" onClick={() => handleSave('close')}>
              Salvar
            </Button>
            <Button variant="contained" onClick={onCancel}>
              Sair
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default CardEditor;
