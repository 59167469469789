import React from 'react';
import parse from 'html-react-parser';
import CustomAccordion from '../components/utils/CustomAccordion/CustomAccordion';
import Comment from '../components/utils/Comment/Comment';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './parser.css';

export const parseContent = (html: string) => {
  const regexes = {
    accordion: /\[\{\{([\s\S]*?)\}\}\{\{([\s\S]*?)\}\}\]/,
    commentType: /(?:<p[^>]*>)?(?:<strong>|<em>|<span[^>]*>)?:::comentario (Alerta|Questão|Informação|Erro|Importante)(?:<\/strong>|<\/em>|<\/span>)?(?:<\/p>)?([\s\S]*?)<p[^>]*>---<\/p>([\s\S]*?)<p[^>]*>---<\/p>/, //<p[^>]*>:::comentario (Alerta|Questão|Informação|Erro|Importante)<\/p>([\s\S]*?)<p[^>]*>---<\/p>([\s\S]*?)<p[^>]*>---<\/p>/,
    commentGeneric: /<p[^>]*>:::comentario\s*\{([\s\S]*?)\}\s*\{([\s\S]*?)\}<\/p><p[^>]*>([\s\S]*?)<\/p><p[^>]*>---<\/p>/,
    noteWithTitle: /(?:<p[^>]*>)?(?:<strong>|<em>|<span[^>]*>)?:::nota (Alerta|Questão|Informação|Erro|Importante|Lei|Jurisprudência)(?:<\/strong>|<\/em>|<\/span>)?(?:<\/p>)?([\s\S]*?)?<p[^>]*>---<\/p>([\s\S]*?)<p[^>]*>---<\/p>/,
    noteWithoutTitle: /<p[^>]*>::nota\s*(Alerta|Questão|Informação|Erro|Importante|Jurisprudência|Lei)<\/p>([\s\S]*?)<p[^>]*>--<\/p>/,
  };

  let elements: any = [];
  let remainingHtml = html;
  let keyCounter = 0; // Contador para garantir chaves únicas e estáveis
  
  const addParsedText = (text: string) => {
    if (text.trim()) {
      elements.push(<div key={`text-${keyCounter++}`}>{parse(text)}</div>);
    }
  };

  const processNextMatch = () => {
    let closestMatch = null;
    let closestType = null;
    let closestIndex = Infinity;

    for (const [key, regex] of Object.entries(regexes)) {
      regex.lastIndex = 0;
      const match = regex.exec(remainingHtml);
      if (match && match.index < closestIndex) {
        closestMatch = match;
        closestType = key;
        closestIndex = match.index;
      }
    }

    if (!closestMatch) {
      addParsedText(remainingHtml);
      remainingHtml = '';
      return;
    }

    const [fullMatch, ...groups] = closestMatch;

    addParsedText(remainingHtml.slice(0, closestMatch.index));

    // As chaves agora são estáveis e garantidas por `keyCounter`
    switch (closestType) {
      case 'accordion':
        const [frontText, backText] = groups;
        elements.push(
          <CustomAccordion key={`accordion-${keyCounter++}`} defaultExpanded={false}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {parse(frontText)}
            </AccordionSummary>
            <AccordionDetails>
              {parse(backText)}
            </AccordionDetails>
          </CustomAccordion>
        );
        break;
      case 'commentType':
        const [commentType, question, answer] = groups;
        const bgColor = getCommentBackgroundColor(commentType);
        elements.push(
          <CustomAccordion key={`commentType-${keyCounter++}`} defaultExpanded={false} bgColor={bgColor}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Comment type={commentType} title={question} text="" />
            </AccordionSummary>
            <AccordionDetails>
              {parse(answer.trim())}
            </AccordionDetails>
          </CustomAccordion>
        );
        break;
      case 'commentGeneric':
        const [color, title, text] = groups;
        elements.push(
          <CustomAccordion key={`commentGeneric-${keyCounter++}`} defaultExpanded={false} bgColor={color}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Comment color={color} title={title} text="" />
            </AccordionSummary>
            <AccordionDetails>
              {parse(text.trim())}
            </AccordionDetails>
          </CustomAccordion>
        );
        break;
      case 'noteWithTitle':
        const [noteType, noteTitle, noteText] = groups;
        elements.push(
          <Comment key={`noteWithTitle-${keyCounter++}`} type={noteType} title={noteTitle} text={noteText.trim()} />
        );
        break;
      case 'noteWithoutTitle':
        const [noteTypeNoTitle, noteTextNoTitle] = groups;
        elements.push(
          <Comment key={`noteWithoutTitle-${keyCounter++}`} type={noteTypeNoTitle} title="" text={noteTextNoTitle.trim()} />
        );
        break;
      default:
        break;
    }

    remainingHtml = remainingHtml.slice(closestMatch.index + fullMatch.length);
  };

  while (remainingHtml.length > 0) {
    processNextMatch();
  }

  return elements;
};


const getCommentBackgroundColor = (type: string) => {
  switch (type.toLowerCase()) {
    case 'alerta':
      return '#fff3cd';
    case 'questão':
      return '#fcfcf5';
    case 'informação':
      return '#d4edda';
    case 'erro':
      return '#f8d7da';
    case 'importante':
      return '#fff8e1';
    case 'jurisprudencia':
      return '#dcedf7';
    case 'lei':
      return '#ebeae8'
    default:
      return 'yellow'; // Default background color for generic comments
  }
};
