import React, { useState, useEffect } from 'react';
import {
  fetchUsuarios,
  vincularUsuariosAoCurso,
  desvincularUsuarioDoCurso,
  fetchTodosCursos,
  fetchUsuariosPorCurso,
  updateTipoUsuario, // Nova função para atualizar o tipo de vínculo do usuário
} from '../services/api';
import { Button, Select, MenuItem, Grid, List, ListItem, ListItemText, Typography } from '@mui/material';

const UsuariosAdmin: React.FC = () => {
  const [usuarios, setUsuarios] = useState([]); // Todos os usuários
  const [usuariosCurso, setUsuariosCurso] = useState([]); // Usuários cadastrados no curso
  const [usuariosNaoCadastrados, setUsuariosNaoCadastrados] = useState([]); // Usuários não cadastrados no curso
  const [cursoId, setCursoId] = useState<number | null>(null);
  const [cursoSelecionado, setCursoSelecionado] = useState(null);
  const [selectedUsuarios, setSelectedUsuarios] = useState<number[]>([]);

  useEffect(() => {
    loadCursos();
    loadUsuarios(); // Carregar todos os usuários
  }, []);

  useEffect(() => {
    if (cursoId) {
      loadUsuariosPorCurso(); // Carregar usuários cadastrados no curso
    }
  }, [cursoId]);

  const loadUsuarios = async () => {
    const response = await fetchUsuarios();
    setUsuarios(response.data);
  };

  const loadCursos = async () => {
    const response = await fetchTodosCursos();
    setCursoSelecionado(response.data);
  };

  const loadUsuariosPorCurso = async () => {
    if (cursoId) {
      const response = await fetchUsuariosPorCurso(cursoId);
      // Agora, o response.data contém o tipo de vínculo (aluno, professor, admin)
      setUsuariosCurso(response.data);

      const naoCadastrados = usuarios.filter(
        (usuario) => !response.data.some((u: any) => u.id === usuario.id)
      );
      setUsuariosNaoCadastrados(naoCadastrados);
    }
  };

  const handleVincularUsuarios = async () => {
    if (cursoId && selectedUsuarios.length > 0) {
      await vincularUsuariosAoCurso(cursoId, selectedUsuarios); // Vincula sempre como 'aluno'
      loadUsuariosPorCurso();
      setSelectedUsuarios([]);
    }
  };

  const handleDesvincularUsuario = async (usuarioId: number) => {
    await desvincularUsuarioDoCurso(cursoId, usuarioId);
    loadUsuariosPorCurso();
  };

  const handleSelectUsuario = (usuarioId: number) => {
    setSelectedUsuarios((prevSelected) =>
      prevSelected.includes(usuarioId)
        ? prevSelected.filter((id) => id !== usuarioId)
        : [...prevSelected, usuarioId]
    );
  };

  const handleChangeTipoUsuario = async (usuarioId: number, novoTipo: 'aluno' | 'professor' | 'admin') => {
    if (cursoId) {
      await updateTipoUsuario(cursoId, usuarioId, novoTipo);
      loadUsuariosPorCurso(); // Atualiza a lista após a alteração
    }
  };

  return (
    <div className="usuarios-admin">
      <h3>Vincular Usuários ao Curso</h3>

      <Select
        label="Selecionar Curso"
        value={cursoId || ''}
        onChange={(e) => setCursoId(Number(e.target.value))}
        fullWidth
      >
        {cursoSelecionado?.map((curso: any) => (
          <MenuItem key={curso.id} value={curso.id}>
            {curso.nome}
          </MenuItem>
        ))}
      </Select>

      <Grid container spacing={2} style={{ marginTop: 16 }}>
        <Grid item xs={5}>
          <h4>Usuários Não Cadastrados</h4>
          <List>
            {usuariosNaoCadastrados.map((usuario) => (
              <ListItem
                key={usuario.id}
                button
                onClick={() => handleSelectUsuario(usuario.id)}
                selected={selectedUsuarios.includes(usuario.id)}
              >
                <ListItemText primary={usuario.nome} />
              </ListItem>
            ))}
          </List>
        </Grid>

        <Grid item xs={2}>
          <Button
            onClick={handleVincularUsuarios}
            variant="contained"
            color="primary"
            fullWidth
            disabled={!cursoId || selectedUsuarios.length === 0}
          >
            Vincular
          </Button>
        </Grid>

        <Grid item xs={5}>
          <h4>Usuários Cadastrados</h4>
          <List>
            {usuariosCurso.map((usuario) => (
              <ListItem key={usuario.id}>
                <ListItemText primary={usuario.nome} />
                <Select
                  value={usuario.tipoUsuario || 'aluno'}
                  onChange={(e) => handleChangeTipoUsuario(usuario.id, e.target.value as 'aluno' | 'professor' | 'admin')}
                >
                  <MenuItem value="aluno">Aluno</MenuItem>
                  <MenuItem value="professor">Professor</MenuItem>
                  <MenuItem value="admin">Admin</MenuItem>
                </Select>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleDesvincularUsuario(usuario.id)}
                >
                  Desvincular
                </Button>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </div>
  );
};

export default UsuariosAdmin;
