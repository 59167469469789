import React, { useState, useEffect, useRef } from 'react';
import { Button, Box, Tabs, Tab } from '@mui/material';
import JoditEditor from 'jodit-react';
import './PreencherQuadroEditor.css';

interface ProcessData {
  title: string;
  processes: {
    [key: string]: {
      [key: string]: string[];
    };
  };
  processGroups: string[];
  knowledgeAreas: string[];
  comment?: string;
}

interface PreencherQuadroEditorProps {
  initialData: ProcessData;
  onSave: (data: ProcessData) => void;
  onCancel: () => void;
}

const PreencherQuadroEditor: React.FC<PreencherQuadroEditorProps> = ({ initialData, onSave, onCancel }) => {
  const [data, setData] = useState<ProcessData>(initialData);
  const [focusAreaIndex, setFocusAreaIndex] = useState<number | null>(null);
  const [focusGroupIndex, setFocusGroupIndex] = useState<number | null>(null);
  const [newArea, setNewArea] = useState<string>('');
  const [newGroup, setNewGroup] = useState<string>('');
  const [currentTab, setCurrentTab] = useState(0);
  const commentEditorRef = useRef<any>(null);

  useEffect(() => {
    setData(initialData);
  }, [initialData]);

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, title: e.target.value });
  };

  const handleAddArea = () => {
    if (newArea.trim()) {
      const newKnowledgeAreas = [...data.knowledgeAreas, newArea];
      const newProcesses: { [key: string]: { [key: string]: string[] } } = { ...data.processes, [newArea]: {} };
      data.processGroups.forEach(group => {
        newProcesses[newArea][group] = [];
      });
      setData({ ...data, knowledgeAreas: newKnowledgeAreas, processes: newProcesses });
      setNewArea('');
      setFocusAreaIndex(newKnowledgeAreas.length - 1);
    }
  };

  const handleAddGroup = () => {
    if (newGroup.trim()) {
      const newProcessGroups = [...data.processGroups, newGroup];
      const newProcesses = { ...data.processes };
      data.knowledgeAreas.forEach(area => {
        if (!newProcesses[area]) {
          newProcesses[area] = {};
        }
        newProcesses[area][newGroup] = [];
      });
      setData({ ...data, processGroups: newProcessGroups, processes: newProcesses });
      setNewGroup('');
      setFocusGroupIndex(newProcessGroups.length - 1);
    }
  };

  const handleProcessChange = (area: string, group: string, value: string) => {
    const newProcesses = { ...data.processes };
    newProcesses[area][group] = value.split('\n');
    setData({ ...data, processes: newProcesses });
  };

  const handleTabChange = (_event: React.ChangeEvent<{}>, newTab: number) => {
    if (currentTab === 1 && commentEditorRef.current) {
      setData({ ...data, comment: commentEditorRef.current.value });
    }
    setCurrentTab(newTab);
  };

  const handleSave = () => {
    const currentComment = commentEditorRef.current?.value || data.comment;
    onSave({ ...data, comment: currentComment });
  };

  const config = {
    readonly: false,
    toolbar: true,
    height: 400,
    autofocus: true,
    buttons: [
      'source', '|',
      'bold', 'italic', 'underline', 'strikethrough', '|',
      'superscript', 'subscript', '|',
      'ul', 'ol', '|',
      'outdent', 'indent', '|',
      'font', 'fontsize', 'brush', 'paragraph', '|',
      'image', 'video', 'table', 'link', '|',
      'align', 'undo', 'redo', '|',
      'hr', 'eraser', 'copyformat', 'symbols', '|',
      'fullsize', 'selectall', 'print', 'about', '|'
    ]
  };

  return (
    <div className="preencher-quadro-editor">
      <Tabs value={currentTab} onChange={handleTabChange}>
        <Tab label="Tabela" />
        <Tab label="Comentário" />
      </Tabs>
      {currentTab === 0 && (
        <Box>
      <div className="preencher-quadro-editor-header">
        <input type="text" value={data.title} onChange={handleTitleChange} placeholder="Title" />
      </div>
      <div className="preencher-quadro-editor-body">
        <div className="preencher-quadro-editor-add">
          <input type="text" value={newArea} onChange={(e) => setNewArea(e.target.value)} placeholder="Nova Linha" />
          <button onClick={handleAddArea}>Add Linha</button>
        </div>
        <div className="preencher-quadro-editor-add">
          <input type="text" value={newGroup} onChange={(e) => setNewGroup(e.target.value)} placeholder="Nova Coluna" />
          <button onClick={handleAddGroup}>Add Coluna</button>
        </div>
        <table className="preencher-quadro-editor-table">
          <thead>
            <tr>
              <th></th>
              {data.processGroups.map((group, groupIndex) => (
                <th key={groupIndex}>{group}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.knowledgeAreas.map((area, areaIndex) => (
              <tr key={areaIndex}>
                <td>{area}</td>
                {data.processGroups.map((group, groupIndex) => (
                  <td key={groupIndex}>
                    <textarea
                      value={(data.processes[area][group] || []).join('\n')}
                      onChange={(e) => handleProcessChange(area, group, e.target.value)}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      </Box> )}
      {currentTab === 1 && (
        <Box>
          <JoditEditor ref={commentEditorRef} value={data.comment || ''} config={config} />
        </Box>
      )}
      <div className="preencher-quadro-editor-footer">
        <button onClick={onCancel}>Sair</button>
        <button onClick={handleSave}>Salvar</button>
      </div>
    </div>
  );
};

export default PreencherQuadroEditor;
