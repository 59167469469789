import React from 'react';
import { Button } from '@mui/material';
import './HomeDeslogado.css';

const HomeDeslogado: React.FC = () => {
  return (
    <div className="home-deslogado-container">
      <header className="home-header">
        <h1 className="home-title">Bem-vindo ao MemoNow</h1>
        <p className="home-subtitle">
          A plataforma ideal para otimizar seus estudos e alcançar o sucesso em concursos públicos.
        </p>
        <Button variant="contained" className="home-signup-button" href="/cadastro">
          Cadastre-se Gratuitamente
        </Button>
      </header>
      <section className="home-section">
        <h2>Por que usar o MemoNow?</h2>
        <ul className="home-advantages-list">
          <li>
            <strong>Organização Personalizada:</strong> Centralize seus estudos e crie um plano de aprendizado eficiente.
          </li>
          <li>
            <strong>Revisão Espaçada:</strong> Baseada em estudos científicos, melhora significativamente a retenção de longo prazo.
          </li>
          <li>
            <strong>Memorização Otimizada:</strong> Técnicas comprovadas ajudam você a se destacar em concursos e provas.
          </li>
          <li>
            <strong>Ferramentas Intuitivas:</strong> Interface amigável que facilita a revisão e o aprendizado.
          </li>
        </ul>
      </section>
      <section className="home-section">
        <h2>Ciência por Trás da Revisão Espaçada</h2>
        <p>
          Estudos mostram que a repetição em intervalos crescentes reforça a memória, combatendo o esquecimento natural. 
          Com o MemoNow, você revisa no momento certo para consolidar o conhecimento de forma duradoura.
        </p>
      </section>
      <section className="home-section">
        <h2>Vantagens da Memorização para Concursos</h2>
        <p>
          Uma boa memorização não apenas acelera o aprendizado, mas também aumenta a confiança durante provas. 
          O MemoNow combina estratégias modernas para ajudá-lo a fixar conteúdos extensos com eficiência.
        </p>
      </section>
      <section className="home-section">
        <h2>Facilidade do MemoNow</h2>
        <p>
          Nossa plataforma é desenvolvida para ser prática e adaptável. Acesse seus estudos a qualquer hora, 
          de qualquer dispositivo, e aproveite as ferramentas avançadas para otimizar sua preparação.
        </p>
      </section>
    </div>
  );
};

export default HomeDeslogado;
