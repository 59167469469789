import React, { useState, useEffect } from 'react';
import { TreeTable, TreeTableTogglerTemplateOptions } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { TreeNode } from 'primereact/treenode';
import { Menu, MenuItem, TextField, IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions, Tabs, Tab } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FolderIcon from '@mui/icons-material/Folder';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import AddComponenteModal from './AddComponenteModal';
import RevisaoEspacadaViewer from './RevisaoEspacadaViewer';
import RevisaoEspacadaEdicaoPage from './RevisaoEspacadaEdicaoPage';
import AddComponentesDePaginas from './AddComponentesDePaginas';
import Toast from './Toast';
import './RevisaoEspacadaPage.css';
import { getComponentesRevisao, syncWithServer, saveOfflineChange  } from '../services/indexedDBService';
import { fetchBaralhosPorCurso, createBaralho, updateBaralho, deleteBaralho, getBaralhoDeleteInfo, fetchItensRevisaoEspacadaByBaralho, fetchItensRevisaoEspacadaByCurso, syncDataIfNeeded, fetchTipoUsuarioNoCurso } from '../services/api';

interface Baralho {
  id: number;
  nome: string;
  baralhoPai: Baralho | null;
  subBaralhos: Baralho[];
  componentes: any[];
  novos?: number;
  aprender?: number;
  revisar?: number;
}

interface RevisaoEspacadaPageProps {
  usuarioId: number;
  cursoId: number;
}

const RevisaoEspacadaPage: React.FC<RevisaoEspacadaPageProps> = ({ usuarioId, cursoId }) => {
  const [baralhos, setBaralhos] = useState<TreeNode[]>([]);
  const [selectedBaralho, setSelectedBaralho] = useState<Baralho | null>(null);
  const [contextMenu, setContextMenu] = useState<{ mouseX: number; mouseY: number } | null>(null);
  const [isRenaming, setIsRenaming] = useState(false);
  const [isAddSubBaralho, setIsAddSubBaralho] = useState(false);
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);
  const [newName, setNewName] = useState(''); 
  const [expandedKeys, setExpandedKeys] = useState<{ [key: string]: boolean }>({});
  const [deleteInfo, setDeleteInfo] = useState({ subBaralhosCount: 0, cardsCount: 0 });
  const [isAddComponentModalOpen, setIsAddComponentModalOpen] = useState(false);
  const [isAddComponentesDePaginasModalOpen, setIsAddComponentesDePaginasModalOpen] = useState(false);
  const [selectedBaralhoIds, setSelectedBaralhoIds] = useState<number[]>([]);
  const [isRevisaoViewerOpen, setIsRevisaoViewerOpen] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [tipoUsuario, setTipoUsuario] = useState<'aluno' | 'professor' | 'admin' | null>(null); // Estado para armazenar tipo do usuário
  const [isOffline, setIsOffline] = useState(!navigator.onLine); // Estado para status de conexão
  const [showOfflineToast, setShowOfflineToast] = useState(true);


  useEffect(() => {
    const handleOnlineStatus = async () => {
      const currentlyOnline = navigator.onLine;
      setIsOffline(!currentlyOnline);
      if (currentlyOnline && isOffline) {
        await syncDataIfNeeded(usuarioId, cursoId);
      }
      
    };    

    window.addEventListener('online', handleOnlineStatus);
    window.addEventListener('offline', handleOnlineStatus);

    return () => {
      window.removeEventListener('online', handleOnlineStatus);
      window.removeEventListener('offline', handleOnlineStatus);
    };
  }, [isOffline]);


  useEffect(() => {
    if (isOffline) {
      setShowOfflineToast(true);
    }
  }, [isOffline]);

  useEffect(() => {
    const fetchData = async () => {
      if (!navigator.onLine) {
        // Carregar dados do IndexedDB caso esteja offline
        const offlineData = await getComponentesRevisao(usuarioId, cursoId);
        
        const formatOfflineData = (data: any[]): Baralho[] => {
          return data.map(item => ({
            id: item.id,
            nome: item.nome || 'Nome Padrão',
            baralhoPai: item.baralhoPai || null,
            subBaralhos: item.subBaralhos ? formatOfflineData(item.subBaralhos) : [],
            componentes: item.componentes || [],
            novos: item.novos || 0,
            aprender: item.aprender || 0,
            revisar: item.revisar || 0,
          }));
        };
        
        // Formatando dados offline para o formato esperado
        const offlineDataFormatted = formatOfflineData(offlineData);
        const baralhosDataOffline = await transformDataOffline(offlineDataFormatted);
        setBaralhos(baralhosDataOffline); // Carregar dados transformados do IndexedDB
        return;
      }
      
      try {
        const response = await fetchBaralhosPorCurso(cursoId);
        const itensRevisao = (await fetchItensRevisaoEspacadaByCurso(cursoId, usuarioId)).data;
        const baralhosData = await transformData(response.data, itensRevisao);
        setBaralhos(baralhosData);

        await syncWithServer(baralhosData, usuarioId, cursoId);
      } catch (error) {
        console.log(error);
      }
    };
    
    fetchData();
  
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
  
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [cursoId]);

  const formatOfflineData = (data: any[]): Baralho[] => {
    return data.map(item => ({
      id: item.id,
      nome: item.nome || 'Nome Padrão',
      baralhoPai: item.baralhoPai || null,
      subBaralhos: item.subBaralhos ? formatOfflineData(item.subBaralhos) : [],
      componentes: item.componentes || [],
      novos: item.novos || 0,
      aprender: item.aprender || 0,
      revisar: item.revisar || 0,
    }));
  };
  
  const transformData = async (data: Baralho[], itensRevisao: any[]): Promise<TreeNode[]> => {
    const today = new Date().toLocaleDateString('en-CA');
    const itensMap = new Map<number, any[]>();

    // Organiza itens de revisão por baralhoId para acessar rapidamente durante o mapeamento
    itensRevisao.forEach(item => {
      const baralhoId = item.baralho.id;
      if (!itensMap.has(baralhoId)) {
        itensMap.set(baralhoId, []);
      }
      itensMap.get(baralhoId)?.push(item);
    });

    const calculateCounts = (baralhoId: number) => {
      const itens = itensMap.get(baralhoId) || [];
      return {
        novos: itens.filter(item => item.status === 'novo' && item.data_proxima_revisao <= today).length,
        aprender: itens.filter(item => item.status === 'aprender' && item.data_proxima_revisao <= today).length,
        revisar: itens.filter(item => item.status === 'a revisar' && item.data_proxima_revisao <= today).length,
      };
    };

    return Promise.all(
      data.map(async (item): Promise<TreeNode> => {
        const { novos, aprender, revisar } = calculateCounts(item.id);

        return {
          key: item.id.toString(),
          data: {
            id: item.id,
            nome: item.nome,
            novos,
            aprender,
            revisar,
          },
          children: item.subBaralhos ? await transformData(item.subBaralhos, itensRevisao) : [],
        };
      })
    );
  };

  const transformDataOffline = async (data: Baralho[]): Promise<TreeNode[]> => {
    return Promise.all(
      data.map(async (item): Promise<TreeNode> => ({
        key: item.id.toString(),
        data: {
          id: item.id,
          nome: item.nome,
          novos: item.novos || 0,
          aprender: item.aprender || 0,
          revisar: item.revisar || 0,
        },
        children: item.subBaralhos ? await transformDataOffline(item.subBaralhos) : [],
      }))
    );
  };
  
  

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
  };

  const getAllBaralhoIds = (node: TreeNode): number[] => {
    let ids = [node.data.id];
    if (node.children && node.children.length > 0) {
      node.children.forEach((childNode) => {
        ids = ids.concat(getAllBaralhoIds(childNode));
      });
    }
    return ids;
  };

  const handleBaralhoClick = (node: TreeNode) => {
    const ids = getAllBaralhoIds(node);
    setSelectedBaralhoIds(ids);
    setIsRevisaoViewerOpen(true);
  };

  const handleContextMenu = (event: React.MouseEvent, baralho: Baralho) => {
    event.preventDefault();
    setSelectedBaralho(baralho);
    setContextMenu({ mouseX: event.clientX - 2, mouseY: event.clientY - 4 });
  };

  const handleRenameBaralho = (baralho: Baralho) => {
    setSelectedBaralho(baralho);
    setNewName(baralho.nome);
    setIsRenaming(true);
    setContextMenu(null);
  };

  const handleSaveRename = async () => {
    if (selectedBaralho) {
      if (!navigator.onLine) {
        // Salva a operação de renomeação offline
        await saveOfflineChange({
          type: 'baralho',
          action: 'rename',
          data: { baralhoId: selectedBaralho.id, novoNome: newName },
        });
        const offlineData = await getComponentesRevisao(usuarioId, cursoId);
        const formattedOfflineData = offlineData.map(item => ({
          id: item.id,
          nome: item.dados.nome || 'Nome Padrão',
          baralhoPai: item.dados.baralhoPai || null,
          subBaralhos: [],
          componentes: [],
          novos: 0,
          aprender: 0,
          revisar: 0,
        }));
        const baralhosData = await transformDataOffline(formattedOfflineData);
        setBaralhos(baralhosData);
        setIsRenaming(false);
        return;
      }
      try {
        await updateBaralho(selectedBaralho.id, newName);
        const response = await fetchBaralhosPorCurso(cursoId);
        const itensRevisao = await fetchItensRevisaoEspacadaByCurso(cursoId, usuarioId);
        const baralhosData = await transformData(response.data, itensRevisao.data);
        setBaralhos(baralhosData);
        setIsRenaming(false);
      } catch (error) {
        console.log(error)
      }
    }
  };

  const handleAddBaralho = async () => {
    const nomeBaralho = prompt('Digite o nome do novo baralho:');
    if (nomeBaralho) {
      if (!navigator.onLine) {
        // Salva a alteração offline
        await saveOfflineChange({
          type: 'baralho',
          action: 'add',
          data: { cursoId, nome: nomeBaralho },
        });

        // Obtém e formata os dados offline
        const offlineData = await getComponentesRevisao(usuarioId, cursoId);

        // Formata os dados offline no tipo Baralho
        const formattedOfflineData = offlineData.map(item => ({
          id: item.id,
          nome: item.dados.nome || 'Nome Padrão',
          baralhoPai: null,
          subBaralhos: [],
          componentes: [],
          novos: 0,
          aprender: 0,
          revisar: 0,
        }));

        // Transforma e exibe os dados formatados
        const baralhosData = await transformDataOffline(formattedOfflineData);
        setBaralhos(baralhosData);
        return;
      }
      try {
        const response = await createBaralho(cursoId, nomeBaralho);
        const updatedBaralhos = await fetchBaralhosPorCurso(cursoId);
        const itensRevisao = await fetchItensRevisaoEspacadaByCurso(cursoId, usuarioId);
        const baralhosData = await transformData(updatedBaralhos.data, itensRevisao.data);
        setBaralhos(baralhosData);
      } catch (error) {
        console.log(error)
      }
    }
  };
  

  const handleAddSubBaralho = async () => {
    if (selectedBaralho) {
      const nomeSubBaralho = newName;
      if (nomeSubBaralho) {
        if (!navigator.onLine) {
          // Salva a operação offline
          await saveOfflineChange({
            type: 'baralho',
            action: 'add-sub',
            data: { cursoId, nome: nomeSubBaralho, parentId: selectedBaralho.id },
          });

          // Obtém e formata os dados offline
          const offlineData = await getComponentesRevisao(usuarioId, cursoId);
          const formattedOfflineData = offlineData.map(item => ({
            id: item.id,
            nome: item.dados.nome || 'Nome Padrão',
            baralhoPai: item.dados.baralhoPai || null,
            subBaralhos: [],
            componentes: [],
            novos: 0,
            aprender: 0,
            revisar: 0,
          }));
          const baralhosData = await transformDataOffline(formattedOfflineData);
          setBaralhos(baralhosData);
          return;
        }
        try {
          await createBaralho(cursoId, nomeSubBaralho, selectedBaralho.id);
          const response = await fetchBaralhosPorCurso(cursoId);
          const itensRevisao = await fetchItensRevisaoEspacadaByCurso(cursoId, usuarioId);
          const baralhosData = await transformData(response.data, itensRevisao.data);
          setBaralhos(baralhosData);
        } catch (error) {
          
        }
        setIsAddSubBaralho(false);
        setNewName('');
      }
    }
  };
  
  const handleDeleteBaralho = async () => {
    if (selectedBaralho) {
      try {
        const { data } = await getBaralhoDeleteInfo(selectedBaralho.id);
        setDeleteInfo(data);
        setIsDeleteConfirm(true);
      } catch (error) {
        if (!navigator.onLine) {
          // Salva a operação offline
          await saveOfflineChange({
            type: 'baralho',
            action: 'delete',
            data: { baralhoId: selectedBaralho.id },
          });
  
          // Obtém e formata os dados offline
          const offlineData = await getComponentesRevisao(usuarioId, cursoId);
          const formattedOfflineData = offlineData.map(item => ({
            id: item.id,
            nome: item.dados.nome || 'Nome Padrão',
            baralhoPai: item.dados.baralhoPai || null,
            subBaralhos: [],
            componentes: [],
            novos: 0,
            aprender: 0,
            revisar: 0,
          }));
          const baralhosData = await transformDataOffline(formattedOfflineData);
          setBaralhos(baralhosData);
        }
      }
    }
  };
  
  const confirmDeleteBaralho = async () => {
    if (selectedBaralho) {
      if (!navigator.onLine) {
        // Salva a operação de exclusão offline
        await saveOfflineChange({
          type: 'baralho',
          action: 'delete',
          data: { baralhoId: selectedBaralho.id },
        });
        const offlineData = await getComponentesRevisao(usuarioId, cursoId);
        const formattedOfflineData = offlineData.map(item => ({
          id: item.id,
          nome: item.dados.nome || 'Nome Padrão',
          baralhoPai: item.dados.baralhoPai || null,
          subBaralhos: [],
          componentes: [],
          novos: 0,
          aprender: 0,
          revisar: 0,
        }));
        const baralhosData = await transformDataOffline(formattedOfflineData);
        setBaralhos(baralhosData);
        setIsDeleteConfirm(false);
        return;
      }
      try {
        await deleteBaralho(selectedBaralho.id);
        const response = await fetchBaralhosPorCurso(cursoId);
        const itensRevisao = await fetchItensRevisaoEspacadaByCurso(cursoId, usuarioId);
        const baralhosData = await transformData(response.data, itensRevisao.data);
        setBaralhos(baralhosData);
        setIsDeleteConfirm(false);
      } catch (error) {
        console.log(error)
      }
    }
  };

  const calculateTotals = (node: TreeNode) => {
    let novos = node.data.novos || 0;
    let aprender = node.data.aprender || 0;
    let revisar = node.data.revisar || 0;

    if (node.children && node.children.length > 0) {
      node.children.forEach((childNode: TreeNode) => {
        const subTotals = calculateTotals(childNode);
        novos += subTotals.novos;
        aprender += subTotals.aprender;
        revisar += subTotals.revisar;
      });
    }

    return { novos, aprender, revisar };
  };

  const onToggle = (event: { value: any }) => {
    setExpandedKeys(event.value);
  };

  const togglerTemplate = (node: TreeNode, options: TreeTableTogglerTemplateOptions) => {
    if (!node) {
      return null;
    }

    const expanded = expandedKeys[node.key];
    return (
      <button
        type="button"
        className="RevisaoEspacadaPage-togglerButton"
        style={options.buttonStyle}
        tabIndex={-1}
        onClick={options.onClick}
      >
        {expanded ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
      </button>
    );
  };

  const folderTemplate = (node: TreeNode) => {
    const expanded = expandedKeys[node.key];
    return (
      <>
        {expanded ? <FolderOpenIcon /> : <FolderIcon />}
        <span className="RevisaoEspacadaPage-baralhoNome" onClick={() => handleBaralhoClick(node)}>
          {node.data.nome}
        </span>
      </>
    );
  };

  const actionTemplate = (node: TreeNode) => {
    if (tipoUsuario === 'aluno') return null; // Não renderiza a coluna de ações para alunos
    return (
      <IconButton className="RevisaoEspacadaPage-actionButton" onClick={(event) => handleContextMenu(event, node.data)}>
        <MoreVertIcon />
      </IconButton>
    );
  };

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Totais:" colSpan={1} className="RevisaoEspacadaPage-footerLabel" />
        <Column footer={baralhos.reduce((sum, node) => sum + calculateTotals(node).novos, 0)} className="RevisaoEspacadaPage-totalNovos" />
        <Column footer={baralhos.reduce((sum, node) => sum + calculateTotals(node).aprender, 0)} className="RevisaoEspacadaPage-totalAprender" />
        <Column footer={baralhos.reduce((sum, node) => sum + calculateTotals(node).revisar, 0)} className="RevisaoEspacadaPage-totalRevisar" />
        <Column footer={null} />
      </Row>
    </ColumnGroup>
  );

  const header = <div className="RevisaoEspacadaPage-header">Revisão do Dia</div>;

  const handleSaveComponent = async (type: string, data: any, baralhoId: number) => {
    try {
      const baralhosResponse = await fetchBaralhosPorCurso(cursoId);
      const itensRevisao = await fetchItensRevisaoEspacadaByCurso(cursoId, usuarioId);
      const baralhosData = await transformData(baralhosResponse.data, itensRevisao.data);
      setBaralhos(baralhosData);
      setSuccessMessage(`${type} adicionado com sucesso`);
    } catch (error) {
      console.error('Erro ao salvar o componente:', error);
    }
  };

  const onComponentesAdded = async () => {
    try
    {
      const baralhosResponse = await fetchBaralhosPorCurso(cursoId);
      const itensRevisao = await fetchItensRevisaoEspacadaByCurso(cursoId, usuarioId);
      const baralhosData = await transformData(baralhosResponse.data, itensRevisao.data);
      setBaralhos(baralhosData);
      setSuccessMessage('Componentes adicionados com sucesso!');  // Exibe a mensagem de sucesso
    }
    catch (error) {
      console.error('Erro ao adicionar componentes:', error);
    }
  };

  const handleCloseDialog = async () => {
    setIsRevisaoViewerOpen(false);
    const response = await fetchBaralhosPorCurso(cursoId);
    const itensRevisao = await fetchItensRevisaoEspacadaByCurso(cursoId, usuarioId);
    const baralhosData = await transformData(response.data, itensRevisao.data);
    setBaralhos(baralhosData);
  };

  return (
    <div className="RevisaoEspacadaPage-container">
      {isOffline && (
        <Toast 
          message="Você está offline. Alguns dados podem estar desatualizados." 
          onClose={() => setShowOfflineToast(false)} 
        />
      )}
      {tipoUsuario !== 'aluno' && (
        <Tabs value={tabIndex} onChange={handleTabChange} aria-label="Revisão e Edição">
          <Tab label="Revisão" />
          {!isMobile && <Tab label="Edição" className="RevisaoEspacadaTabEdicao" />}
          {!isMobile && <Tab label="Estatísticas" className="RevisaoEspacadaTabEstatisticas" />}
        </Tabs>
      )}

      {tabIndex === 0 && (
        <div>
          {header}
          {!isMobile && tipoUsuario !== 'aluno' && (
            <Button onClick={handleAddBaralho} variant="contained" color="primary" className="RevisaoEspacadaPage-addBaralhoButton">
              Adicionar Baralho
            </Button>
          )}
          {isRenaming && selectedBaralho && (
            <Dialog open={isRenaming} onClose={() => setIsRenaming(false)}>
              <DialogTitle className="RevisaoEspacadaPage-dialogTitle">Renomear Baralho</DialogTitle>
              <DialogContent className="RevisaoEspacadaPage-dialogContent">
                <TextField label="Novo nome" value={newName} onChange={(e) => setNewName(e.target.value)} fullWidth />
              </DialogContent>
              <DialogActions className="RevisaoEspacadaPage-dialogActions">
                <Button onClick={() => setIsRenaming(false)} color="primary">
                  Cancelar
                </Button>
                <Button onClick={handleSaveRename} color="primary">
                  Salvar
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {isAddSubBaralho && selectedBaralho && (
            <Dialog open={isAddSubBaralho} onClose={() => setIsAddSubBaralho(false)}>
              <DialogTitle className="RevisaoEspacadaPage-dialogTitle">Adicionar Sub-Baralho</DialogTitle>
              <DialogContent className="RevisaoEspacadaPage-dialogContent">
                <TextField label="Nome do Sub-Baralho" value={newName} onChange={(e) => setNewName(e.target.value)} fullWidth />
              </DialogContent>
              <DialogActions className="RevisaoEspacadaPage-dialogActions">
                <Button onClick={() => setIsAddSubBaralho(false)} color="primary">
                  Cancelar
                </Button>
                <Button onClick={handleAddSubBaralho} color="primary">
                  Adicionar
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {isDeleteConfirm && selectedBaralho && (
            <Dialog open={isDeleteConfirm} onClose={() => setIsDeleteConfirm(false)}>
              <DialogTitle className="RevisaoEspacadaPage-dialogTitle">Confirmar Exclusão</DialogTitle>
              <DialogContent className="RevisaoEspacadaPage-dialogContent">
                Tem certeza de que deseja excluir este baralho e todos os seus sub-baralhos e cartões? Isto irá excluir {deleteInfo.subBaralhosCount} sub-baralhos e {deleteInfo.cardsCount} cartões.
              </DialogContent>
              <DialogActions className="RevisaoEspacadaPage-dialogActions">
                <Button onClick={() => setIsDeleteConfirm(false)} color="primary">
                  Cancelar
                </Button>
                <Button onClick={confirmDeleteBaralho} color="secondary">
                  Excluir
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {baralhos.length === 0 ? (
            <p className="RevisaoEspacadaPage-noBaralhosMessage">Nenhum baralho disponível. Adicione um novo baralho para começar.</p>
          ) : (
            <TreeTable
              className="RevisaoEspacadaPage-tabelaBaralhos"
              value={baralhos}
              expandedKeys={expandedKeys}
              onToggle={onToggle}
              togglerTemplate={togglerTemplate}
              footerColumnGroup={footerGroup}
              tableStyle={{ minWidth: '100%', tableLayout: 'auto' }}
              showGridlines={true}
              resizableColumns={true}
              sortMode="multiple"
            >
              <Column
                className="RevisaoEspacadaPage-baralhoNomeColumn"
                field="nome"
                header="Baralho"
                expander
                filter={!isMobile}
                filterPlaceholder="Filtrar por nome"
                sortable
                body={folderTemplate}
                style={{ width: 'auto', whiteSpace: 'nowrap' }}
              />
              <Column
                field="novos"
                header={isMobile ? '' : 'Novos'}
                sortable
                body={(node: TreeNode) => (
                  <span className="RevisaoEspacadaPage-quantidadeNovos">{calculateTotals(node).novos}</span>
                )}
                className="RevisaoEspacadaPage-columnCenter"
                style={{ width: 'auto', textAlign: 'center' }}
              />
              <Column
                field="aprender"
                header={isMobile ? '' : 'Aprender'}
                sortable
                body={(node: TreeNode) => (
                  <span className="RevisaoEspacadaPage-quantidadeAprender">{calculateTotals(node).aprender}</span>
                )}
                className="RevisaoEspacadaPage-columnCenter"
                style={{ width: 'auto', textAlign: 'center' }}
              />
              <Column
                field="revisar"
                header={isMobile ? '' : 'A Revisar'}
                sortable
                body={(node: TreeNode) => (
                  <span className="RevisaoEspacadaPage-quantidadeRevisar">{calculateTotals(node).revisar}</span>
                )}
                className="RevisaoEspacadaPage-columnCenter"
                style={{ width: 'auto', textAlign: 'center' }}
              />
              {!isMobile && tipoUsuario !== 'aluno' && (
                <Column
                  body={actionTemplate}
                  header="Ações"
                  className="TreeTable-Revisao-Acoes"
                  style={{ width: 'auto', textAlign: 'center' }}
                />
              )}
            </TreeTable>
          )}

          <Dialog
            open={isRevisaoViewerOpen}
            onClose={handleCloseDialog}
            fullWidth
            fullScreen={true}
            maxWidth={isFullScreen ? 'xl' : 'md'}
            PaperProps={{
              style: isFullScreen
                ? { width: '100%', height: '100%', margin: 0, overflow: 'hidden' }
                : { width: 'auto', height: 'auto', overflowX: 'hidden' },
            }}
          >
            <DialogTitle className="RevisaoEspacadaPage-dialogTitle">
              Revisão do Dia
              <IconButton
                aria-label={isFullScreen ? 'Restaurar' : 'Maximizar'}
                onClick={() => setIsFullScreen(!isFullScreen)}
                style={{ position: 'absolute', right: 8, top: 8 }}
              >
                {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
              </IconButton>
              <IconButton
                aria-label="Fechar"
                onClick={handleCloseDialog}
                style={{ position: 'absolute', right: 48, top: 8 }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent
              className="RevisaoEspacadaPage-dialogContent"
              style={{ padding: 0, overflowY: 'auto', overflowX: 'hidden' }}
            >
              <RevisaoEspacadaViewer usuarioId={usuarioId} cursoId={cursoId} baralhoIds={selectedBaralhoIds} />
            </DialogContent>
          </Dialog>

          <Menu
            keepMounted
            open={contextMenu !== null}
            onClose={() => setContextMenu(null)}
            anchorReference="anchorPosition"
            anchorPosition={
              contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined
            }
          >
            <MenuItem
              className="RevisaoEspacadaPage-menuItem"
              onClick={() => {
                handleRenameBaralho(selectedBaralho!);
                setContextMenu(null); // Fechar o menu após a ação
              }}
            >
              Renomear
            </MenuItem>
            <MenuItem
              className="RevisaoEspacadaPage-menuItem"
              onClick={() => {
                setNewName('');
                setIsAddSubBaralho(true);
                setContextMenu(null); // Fechar o menu após a ação
              }}
            >
              Adicionar Sub-Baralho
            </MenuItem>
            <MenuItem
              className="RevisaoEspacadaPage-menuItem"
              onClick={() => {
                handleDeleteBaralho();
                setContextMenu(null); // Fechar o menu após a ação
              }}
            >
              Excluir
            </MenuItem>
            <MenuItem
              className="RevisaoEspacadaPage-menuItem"
              onClick={() => {
                setIsAddComponentModalOpen(true);
                setContextMenu(null); // Fechar o menu após a ação
              }}
            >
              Adicionar Componente
            </MenuItem>
            <MenuItem
              className="RevisaoEspacadaPage-menuItem"
              onClick={() => {
                setIsAddComponentesDePaginasModalOpen(true);
                setContextMenu(null); // Fechar o menu após a ação
              }}
            >
              Adicionar Componentes de Páginas
            </MenuItem>
          </Menu>


          {selectedBaralho && (
            <AddComponenteModal
              open={isAddComponentModalOpen}
              onClose={() => setIsAddComponentModalOpen(false)}
              onSave={handleSaveComponent}
              baralhoId={selectedBaralho.id}
              usuarioId={usuarioId}
            />
          )}

          {selectedBaralho && (
            <AddComponentesDePaginas
              open={isAddComponentesDePaginasModalOpen}
              onClose={() => setIsAddComponentesDePaginasModalOpen(false)}
              baralhoId={selectedBaralho.id}
              cursoId={cursoId}
              usuarioId={usuarioId}
              onComponentesAdded={onComponentesAdded}
            />
          )}
        </div>
      )}

      {successMessage && <Toast message={successMessage} onClose={() => setSuccessMessage(null)} />}

      {tabIndex === 1 && (
        <div>
          <RevisaoEspacadaEdicaoPage usuarioId={usuarioId} cursoId={cursoId} />
        </div>
      )}
    </div>
  );
};

export default RevisaoEspacadaPage;
