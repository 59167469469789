import React, { useEffect, useState, useRef } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd';
import { CheckCircleOutline, Replay } from '@mui/icons-material';
import { FaThLarge, FaThList, FaCheck, FaEye, FaEraser, FaTrashAlt, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import './ArrastarSoltarCategoria.css';

interface Category {
  [key: string]: string[];
}

interface ArrastarSoltarCategoriaProps {
  data: {
    title: string;
    question: string;
    categories: Category;
    uncategorizedItems?: string[];
    comment?: string;
  };
}

const ArrastarSoltarCategoria: React.FC<ArrastarSoltarCategoriaProps> = ({ data }) => {
  const [items, setItems] = useState<string[]>([]);
  const [categories, setCategories] = useState<Category>({});
  const [assignedCategories, setAssignedCategories] = useState<Category>({});
  const [uncategorizedItems, setUncategorizedItems] = useState<string[]>(data.uncategorizedItems || []); // Itens sem categoria
  const [result, setResult] = useState('');
  const [showComment, setShowComment] = useState(false); // Estado para exibir ou ocultar o comentário
  const [showRemove, setShowRemove] = useState(false);
  const uniqueId = useRef(Math.random().toString(36).substring(2, 15)).current;

  useEffect(() => {
    if (data) {
      const allItems = [...Object.values(data.categories).flat(), ...data.uncategorizedItems || []];
      setItems(shuffle(allItems));
      setCategories(data.categories);
      setAssignedCategories({});
      if(data.uncategorizedItems && data.uncategorizedItems.length > 0)
      {
        setShowRemove(true);
      }
    }
  }, [data]);

    // Função para remover visualmente o item
    const removeItem = (itemToRemove: string) => {
      setItems((prevItems) => prevItems.filter((item) => item !== itemToRemove));
    };

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
  
    if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
      return;
    }
  
    let movedItem = '';
  
    if (source.droppableId === 'items') {
      const sourceItems = Array.from(items);
      [movedItem] = sourceItems.splice(source.index, 1);
  
      if (destination.droppableId === 'items') {
        sourceItems.splice(destination.index, 0, movedItem);
      } else {
        const destinationItems = Array.from(assignedCategories[destination.droppableId] || []);
        destinationItems.splice(destination.index, 0, movedItem);
        setAssignedCategories((prev) => ({
          ...prev,
          [destination.droppableId]: destinationItems,
        }));
      }
      setItems(sourceItems);
    } else if (source.droppableId in assignedCategories) {
      const sourceItems = Array.from(assignedCategories[source.droppableId]);
      [movedItem] = sourceItems.splice(source.index, 1);
  
      if (destination.droppableId === 'items') {
        // Mover o item de volta para a lista de itens não categorizados
        const destinationItems = Array.from(items);
        destinationItems.splice(destination.index, 0, movedItem);
        setItems(destinationItems);
      } else if (source.droppableId === destination.droppableId) {
        sourceItems.splice(destination.index, 0, movedItem);
        setAssignedCategories((prev) => ({
          ...prev,
          [source.droppableId]: sourceItems,
        }));
      } else {
        const destinationItems = Array.from(assignedCategories[destination.droppableId] || []);
        destinationItems.splice(destination.index, 0, movedItem);
        setAssignedCategories((prev) => ({
          ...prev,
          [source.droppableId]: sourceItems,
          [destination.droppableId]: destinationItems,
        }));
      }
  
      setAssignedCategories((prev) => ({
        ...prev,
        [source.droppableId]: sourceItems,
      }));
    }
  };
  

  const checkCategories = () => {
    let correctCount = 0;
    const dropZones = document.querySelectorAll(
      `#arrastar-soltar-categories-container-${uniqueId} .arrastar-soltar-drop-zone`
    );

    dropZones.forEach((zone) => {
      const category = (zone as HTMLElement).dataset.category;
      const items = zone.querySelectorAll('.arrastar-soltar-item');

      items.forEach((item) => {
        const element = item as HTMLElement;
        const correct = data.categories[category]?.includes(element.textContent || '');
        const outOfOrder = !correct && data.categories[category]?.includes(element.textContent || '');
        const incorrect = !data.categories[category]?.includes(element.textContent || '');

        if (correct) {
          correctCount++;
        }

        updateElementClass(element, correct, outOfOrder, incorrect);
      });
    });

    const totalCount = Object.values(data.categories).flat().length;
    const score = (correctCount / totalCount) * 100;
    setResult(`Você acertou ${correctCount} de ${totalCount} (${score.toFixed(2)}%)`);
  };

  const showAnswers = () => {
    setItems([]);
    setAssignedCategories({ ...data.categories });
    setUncategorizedItems([]); 
  };

  const updateElementClass = (
    element: Element | null,
    correct: boolean,
    outOfOrder: boolean,
    incorrect: boolean
  ) => {
    if (!element) return;

    if (correct) {
      element.classList.add('arrastar-soltar-correct');
      element.classList.remove('arrastar-soltar-incorrect', 'arrastar-soltar-out-of-order');
    } else if (outOfOrder) {
      element.classList.add('arrastar-soltar-out-of-order');
      element.classList.remove('arrastar-soltar-correct', 'arrastar-soltar-incorrect');
    } else if (incorrect) {
      element.classList.add('arrastar-soltar-incorrect');
      element.classList.remove('arrastar-soltar-correct', 'arrastar-soltar-out-of-order');
    }
  };

  const restartGame = () => {
    setResult('');
    const allItems = [...Object.values(categories).flat(), ...data.uncategorizedItems || []]; // Inclui os itens sem categoria
    setItems(shuffle(allItems)); // Embaralha todos os itens
    setAssignedCategories({});
    setUncategorizedItems(data.uncategorizedItems || []); // Restaura os itens sem categoria
  };
  

  const shuffle = (array: any[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const toggleComment = () => {
    setShowComment(!showComment);
  };

  return (
    <div className="arrastar-soltar-container">
      {data.title?<h2>{data.title}</h2>:''}
      <h3>{data.question}</h3>
      <DragDropContext onDragEnd={onDragEnd}>
        <div id={`arrastar-soltar-categories-container-${uniqueId}`} className="arrastar-soltar-categories-container">
          {Object.keys(categories).map((category) => (
            <Droppable key={category} droppableId={category}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="arrastar-soltar-category"
                >
                  <h4>{category}</h4>
                  <div className="arrastar-soltar-drop-zone" data-category={category}>
                    {(assignedCategories[category] || []).map((item, index) => (
                      <Draggable key={item} draggableId={item} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`arrastar-soltar-item ${snapshot.isDragging ? 'arrastar-soltar-dragging' : ''}`}
                            style={{
                              ...provided.draggableProps.style,
                              backgroundColor: snapshot.isDragging ? 'lightgreen' : 'white',
                            }}
                          >
                            {item}
                            {/* Botão de remover com hover */}
                          
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                </div>
              )}
            </Droppable>
          ))}
        </div>
        <Droppable droppableId="items">
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              id={`arrastar-soltar-items-container-${uniqueId}`}
              className="arrastar-soltar-items-container"
            >
              {items.map((item, index) => (
                <Draggable key={item} draggableId={item} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={`arrastar-soltar-item ${snapshot.isDragging ? 'arrastar-soltar-dragging' : ''}`}
                      style={{
                        ...provided.draggableProps.style,
                        backgroundColor: snapshot.isDragging ? 'lightgreen' : 'white',
                      }}
                    >
                      {item}
                      <button
                        className={`${showRemove ? 'remove-item-button':'remove-item-button-invisivel'}`}
                        onClick={() => removeItem(item)}
                      >
                        <FaTrashAlt />
                      </button>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className="arrastar-soltar-buttons">
        <button className="arrastar-soltar-button" onClick={checkCategories}>
          <FaCheck /> <span>Avaliar</span>
        </button>
        <button className="arrastar-soltar-button" onClick={showAnswers}>
          <FaEye /> <span>Mostrar Respostas</span>
        </button>
        <button className="arrastar-soltar-button" onClick={restartGame}>
         <Replay /> <span>Reiniciar</span>
        </button>
      </div>
      <div id={`${result?'arrastar-soltar-result':'result-no'}`}>{result}</div>
      {data.comment &&  data.comment !== '<p><br></p>' && (
        <>
          <div className="accordion" onClick={toggleComment}>
            <span>{showComment ? 'Ocultar Comentário' : 'Saiba mais'}</span>
            {showComment ? <FaChevronUp /> : <FaChevronDown />}
          </div>
          {showComment && <div className="arrastar-soltar-comment" dangerouslySetInnerHTML={{ __html: data.comment }} />}
        </>
      )}
    </div>
  );
};

export default ArrastarSoltarCategoria;
