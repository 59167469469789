import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import TextoEditor from '../components/edicao/TextoEditor/TextoEditor';
import CardEditor from '../components/edicao/CardEditor/CardEditor';
import TermoDefinicaoEditor from '../components/edicao/TermoDefinicaoEditor/TermoDefinicaoEditor';
import TreinoFlashCardsEditor from '../components/edicao/TreinoFlashCardsEditor/TreinoFlashCardsEditor';
import TreinoTermoDefinicaoEditor from '../components/edicao/TreinoTermoDefinicaoEditor/TreinoTermoDefinicaoEditor';
import TimeLineEditor from '../components/edicao/TimeLineEditor/TimeLineEditor';
import OrdenacaoEditor from '../components/edicao/OrdenacaoEditor/OrdenacaoEditor';
import SequencingGameEditor from '../components/edicao/SequencingGameEditor/SequencingGameEditor';
import ImageOcclusionEditor from '../components/edicao/ImageOcclusionEditor/ImageOcclusionEditor';
import PreencherItemCategoriaEditor from '../components/edicao/PreencherItemCategoriaEditor/PreencherItemCategoriaEditor';
import ArrastarSoltarCategoriaEditor from '../components/edicao/ArrastarSoltarCategoriaEditor/ArrastarSoltarCategoriaEditor';
import ExcalidrawEditor from '../components/edicao/ExcalidrawEditor/ExcalidrawEditor';
import PreencherQuadroEditor from '../components/edicao/PreencherQuadroEditor/PreencherQuadroEditor';
import PerguntaMultiplaEscolhaEditor from '../components/edicao/PerguntaMultiplaEscolhaEditor/PerguntaMultiplaEscolhaEditor';
import PerguntaDigitadoEditor from '../components/edicao/PerguntaDigitadoEditor/PerguntaDigitadoEditor';
import ItensSelecao from '../components/organisms/ItensSelecao/ItensSelecao';
import ItensSelecaoEditor from '../components/edicao/ItensSelecaoEditor/ItensSelecaoEditor';
import CompleteTexto from '../components/organisms/CompleteTexto/CompleteTexto';
import { createComponenteRevisao, createItemRevisaoEspacada } from '../services/api';

interface AddComponenteModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (type: string, data: any, baralhoId: number) => void;
  baralhoId: number;
  usuarioId: number;
}

const AddComponenteModal: React.FC<AddComponenteModalProps> = ({ open, onClose, onSave, baralhoId, usuarioId }) => {
  const [selectedType, setSelectedType] = useState<string>('Texto');
  const [componentData, setComponentData] = useState<any>({});
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const [key, setKey] = useState<number>(0); 
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  useEffect(() => {
    if (open) {
      setComponentData({});
      setKey(prevKey => prevKey + 1);
    }
  }, [open]);

  const handleTypeChange = (event: SelectChangeEvent<string>) => {
    setSelectedType(event.target.value as string);
    zeraComponente();
    setKey(prevKey => prevKey + 1);
  };

  const zeraComponente = () => {
    switch (selectedType) {
      case 'Texto':
        setComponentData({ html: '' });
        break;
      case 'CompleteTexto':
        setComponentData({ texto: '' });
        break;
      case 'Card':
        setComponentData({ front: '', back: '', comment: '', mode: 'flip' });
        break;
      case 'TermoDefinicao':
        setComponentData({ termosDefinicoes: [], tiposComponentes: [], termoComo: '', flipOrReveal: 'flip' });
        break;
      case 'TreinoFlashCard':
        setComponentData({ treinoFlashCardsData: [] });
        break;
      case 'TreinoTermoDefinicao':
        setComponentData({ assunto: '', termosDefinicoes: [], tiposComponentes: [], termoComo: '' });
        break;
      case 'TimeLine':
        setComponentData({ title: '', phases: [], considerarOrdem: false });
        break;
      case 'Ordenacao':
        setComponentData({ question: '', stages: [], comment: '' });
        break;
      case 'SequencingGame':
        setComponentData({ title: '', phases: [], activities: [], considerarOrdem: false, comment: '' });
        break;
      case 'ImageOcclusion':
        setComponentData({ imageUrl: '', occlusions: [], imageWidth: 800, imageHeight: 600, mode: 'digitada', withText: true, comment: '' });
        break;
      case 'PreencherItemCategoria':
        setComponentData({ title: '', categories: {}, considerarOrdem: false, comment: '' });
        break;
      case 'ItensSelecao':
          setComponentData({ question: '', correctItems: [], incorrectItems: [], comment: '' });
          break;
      case 'ArrastarSoltarCategoria':
        setComponentData({ title: '', question: '', categories: {}, comment: '' });
        break;
      case 'Excalidraw':
        setComponentData({ elements: [], appState: {} });
        break;
      case 'PreencherQuadro':
        setComponentData({ title: '', processes: {}, processGroups: [], knowledgeAreas: [], comment: '' });
        break;
      case 'PerguntaMultiplaEscolha':
        setComponentData({ question: '', options: [{ text: '', correct: false }], questionType: 'single', comment: '' });
        break;
      case 'PerguntaDigitado':
        setComponentData({ question: '', respostaCorreta: '', comment: '' });
        break;
      default:
        setComponentData({});
        break;
    }
  };

  const handleSaveComponent = async (data: any) => {
    try {
      const response = await createComponenteRevisao(usuarioId, selectedType, data, baralhoId);
      const componenteId = response.data.id;
      onSave(selectedType, data, baralhoId);
      zeraComponente();
      console.log('Component data after reset:', componentData); // Adicione isto para verificar o estado

      if (selectedType === 'Texto' || selectedType=== 'CompleteTexto' || selectedType=== 'TimeLine' || selectedType=== 'Ordenacao' || selectedType=== 'SequencingGame' || selectedType=== 'PreencherItemCategoria' || selectedType=== 'ItensSelecao' || selectedType=== 'ImageOcclusion' || selectedType=== 'ArrastarSoltarCategoria' || selectedType=== 'PreencherQuadro'){
        setKey(prevKey => prevKey + 1); // Reseta o editor, garantindo que o próximo esteja zerado
      }
      //setKey(prevKey => prevKey + 1); // Reseta o editor, garantindo que o próximo esteja zerado
    } catch (error) {
      console.error('Erro ao salvar o componente:', error);
    }
  };

  const handleClose = () => {
    setSelectedType('Texto');
    onClose();
  };

  const renderEditor = () => {
    switch (selectedType) {
      case 'Texto':
        return (
          <TextoEditor
            key={key}
            initialContent={componentData.html || ''}
            onSave={(html) => handleSaveComponent({ html })}
            onCancel={handleClose} 
          />
        );
        case 'CompleteTexto':
          return (
            <TextoEditor
              key={key}
              initialContent={componentData.texto || ''}
              onSave={(texto) => handleSaveComponent({ texto })}
              onCancel={handleClose} 
            />
          );
      case 'Card':
        return (
          <CardEditor
            key={key}
            initialFront={componentData.front || ''}
            initialBack={componentData.back || ''}
            initialComment={componentData.comment || ''}
            initialMode={componentData.mode || 'flip'}
            isNew={true}
            onSave={(front, back, mode, comment) => handleSaveComponent({ front, back, mode, comment })}
            onCancel={handleClose} 
          />
        );
      case 'TermoDefinicao':
        return (
          <TermoDefinicaoEditor
            key={key}
            onSave={(termosDefinicoes, tiposComponentes, termoComo, flipOrReveal) =>
              handleSaveComponent({ termosDefinicoes, tiposComponentes, termoComo, flipOrReveal })
            }
            onCancel={handleClose} 
          />
        );
      case 'TreinoFlashCard':
        return (
          <TreinoFlashCardsEditor
            key={key}
            initialCards={componentData.treinoFlashCardsData || []}
            onSave={(cards) => handleSaveComponent({ treinoFlashCardsData: cards })}
            onCancel={handleClose} 
          />
        );
      case 'TreinoTermoDefinicao':
        return (
          <TreinoTermoDefinicaoEditor
            key={key}
            onSave={(assunto, termosDefinicoes, tiposComponentes, termoComo) =>
              handleSaveComponent({ assunto, termosDefinicoes, tiposComponentes, termoComo })}
              onCancel={handleClose} 
          />
        );
      case 'TimeLine':
        return (
          <TimeLineEditor
            key={key}
            title={componentData.title || ''}
            phases={componentData.phases || []}
            considerarOrdem={componentData.considerarOrdem || false}
            onSave={(title, phases, considerarOrdem) => handleSaveComponent({ title, phases, considerarOrdem })}
            onCancel={handleClose} 
          />
        );
      case 'Ordenacao':
        return (
          <OrdenacaoEditor
            key={key}
            initialStages={componentData.stages || []}
            question={componentData.question || ''}
            comment={componentData.comment || ''}
            onSave={(question, stages) => handleSaveComponent({ question, stages })}
            onCancel={handleClose} 
          />
        );
      case 'SequencingGame':
        return (
          <SequencingGameEditor
            key={key}
            title={componentData.title || ''}
            phases={componentData.phases || []}
            activities={componentData.activities || []}
            considerarOrdem={componentData.considerarOrdem || false}
            comment={componentData.comment || ''}
            onSave={(title, phases, activities, considerarOrdem) =>
              handleSaveComponent({ title, phases, activities, considerarOrdem })
            }
            onCancel={handleClose} 
          />
        );
      case 'ImageOcclusion':
        return (
          <ImageOcclusionEditor
            key={key}
            initialData={{
              imageUrl: componentData.imageUrl || '',
              occlusions: componentData.occlusions || [],
              imageWidth: componentData.imageWidth || 800,
              imageHeight: componentData.imageHeight || 600,
              mode: componentData.mode || 'digitada',
              withText: componentData.withText || true,
              comment: componentData.comment || '',
            }}
            onSave={(data) => handleSaveComponent(data)}
            onCancel={handleClose} 
          />
        );
      case 'PreencherItemCategoria':
        return (
          <PreencherItemCategoriaEditor
            key={key} 
            title={componentData.title || ''}
            categories={componentData.categories || {}}
            considerarOrdem={componentData.considerarOrdem || false}
            comment={componentData.comment || ''}
            onSave={(title, categories, considerarOrdem, comment) =>
              handleSaveComponent({ title, categories, considerarOrdem, comment })}
              onCancel={handleClose} 
          />
        );
      case 'ItensSelecao':
          return (
            <ItensSelecaoEditor
              key={key} 
              question={componentData.question || ''}
              correctItems={componentData.correctItems || []}
              incorrectItems={componentData.incorrectItems || []}
              comment={componentData.comment || ''}
              onSave={(question, correctItems, incorrectItems, comment) =>
                handleSaveComponent({ question, correctItems, incorrectItems, comment })}
                onCancel={handleClose} 
            />
          );
      case 'ArrastarSoltarCategoria':
        return (
          <ArrastarSoltarCategoriaEditor
            key={key}
            title={componentData.title || ''}
            question={componentData.question || ''}
            categories={componentData.categories || {}}
            comment={componentData.comment || ''}
            onSave={(title, question, categories, comment) => handleSaveComponent({ title, question, categories, comment })}
            onCancel={handleClose} 
          />
        );
      case 'Excalidraw':
        return (
          <ExcalidrawEditor
            key={key}
            initialData={{
              elements: componentData.elements || [],
              appState: componentData.appState || {},
            }}
            onSave={(data) => handleSaveComponent(data)}
            onCancel={handleClose} 
          />
        );
        case 'PreencherQuadro':
          return (
              <PreencherQuadroEditor
                  key={key}
                  initialData={{
                      title: componentData.title || '',
                      processes: componentData.processes || {},
                      processGroups: componentData.processGroups || [],
                      knowledgeAreas: componentData.knowledgeAreas || [],
                      comment: componentData.comment || '',
                  }}
                  onSave={(data) => handleSaveComponent(data)}
                  onCancel={handleClose} 
              />
          );      
      case 'PerguntaMultiplaEscolha':
        return (
          <PerguntaMultiplaEscolhaEditor
            key={key}
            initialQuestion={componentData.question || ''}
            initialOptions={componentData.options || [{ text: '', correct: false }]}
            initialQuestionType={componentData.questionType || 'single'}
            initialComment={componentData.comment || ''}
            onSave={(question, options, questionType, comment) => handleSaveComponent({ question, options, questionType, comment })}
            onCancel={handleClose} 
            mode="add"
          />
        );
      case 'PerguntaDigitado':
        return (
          <PerguntaDigitadoEditor
            key={key}
            initialQuestion={componentData.question || ''}
            initialAnswer={componentData.respostaCorreta || ''}
            initialComment={componentData.comment || ''}
            onSave={(question, answer, comment) => handleSaveComponent({ question, respostaCorreta: answer, comment })}
            onCancel={handleClose} 
            mode="add"
          />
        );
      default:
        return null;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={isFullScreen ? 'xl' : 'lg'}
      fullWidth={!isFullScreen} // fullWidth deve ser false para permitir o uso de maxWidth 'xl'
      PaperProps={{
        style: isFullScreen
          ? { width: '100%', height: '100%', margin: 0, maxWidth: '100%', maxHeight: '100%' }
          : {},
      }}
    >
      <DialogTitle>
        Adicionar Componente
        <IconButton
          aria-label={isFullScreen ? 'Restaurar' : 'Maximizar'}
          onClick={() => setIsFullScreen(!isFullScreen)}
          style={{ position: 'absolute', right: 48, top: 8 }}
        >
          {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
        </IconButton>
        <IconButton
          aria-label="Fechar"
          onClick={handleClose}
          style={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <InputLabel id="component-type-label">Tipo de Componente</InputLabel>
          <Select
            labelId="component-type-label"
            value={selectedType}
            onChange={handleTypeChange}
          >
            <MenuItem value="Texto">Texto</MenuItem>
            <MenuItem value='CompleteTexto'>Complete o Texto</MenuItem>
            <MenuItem value="Card">Card</MenuItem>
            <MenuItem value="TermoDefinicao">TermoDefinicao</MenuItem>
            <MenuItem value="TreinoFlashCard">Treino de FlashCards</MenuItem>
            <MenuItem value="TreinoTermoDefinicao">Treino de Termos e Definições</MenuItem>
            <MenuItem value="TimeLine">Fases</MenuItem>
            <MenuItem value="Ordenacao">Ordenação</MenuItem>
            <MenuItem value="SequencingGame">Fases e Itens</MenuItem>
            <MenuItem value="ImageOcclusion">Oclusão de Imagens</MenuItem>
            <MenuItem value="PreencherItemCategoria">Preencher Item por Categoria</MenuItem>
            <MenuItem value="ArrastarSoltarCategoria">Arrastar Soltar por Categoria</MenuItem>
            <MenuItem value="Excalidraw">Diagramas e Desenhos</MenuItem>
            <MenuItem value="PreencherQuadro">Preencher Quadro</MenuItem>
            <MenuItem value="PerguntaMultiplaEscolha">Pergunta Multipla Escolha</MenuItem>
            <MenuItem value="PerguntaDigitado">Pergunta Digitado</MenuItem>
          </Select>
        </FormControl>
        {renderEditor()}
      </DialogContent>
    </Dialog>
  );
};

export default AddComponenteModal;
