import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, Container, Grid, Link, Snackbar, Alert } from '@mui/material';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import './LoginForm.css';

const LoginForm: React.FC = () => {
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [nome, setNome] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
  const { login, user } = useAuth();
  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (user) {
      navigate('/login');
    }

    const handleBeforeInstallPrompt = (e: any) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, [user, navigate]);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('Usuário aceitou a instalação do PWA');
        } else {
          console.log('Usuário recusou a instalação do PWA');
        }
        setDeferredPrompt(null);
      });
    }
  };

  const handleLogin = async () => {
    try {
      const response = await axios.post(`${apiUrl}/auth/login`, { email, senha });
      localStorage.setItem('token', response.data.token);
      login(response.data.usuario, response.data.token);
      navigate('/');
    } catch (error) {
      setErrorMessage('Falha no Login: Senha ou Email incorretos');
    }
  };

  const handleRegister = async () => {
    try {
      await axios.post(`${apiUrl}/auth/register`, { nome, email, senha });
      handleLogin();
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        setErrorMessage('Falha no Cadastro: ' + error.response.data);
      } else {
        setErrorMessage('Falha no Cadastro: Ocorreu um erro inesperado!');
      }
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      isRegistering ? handleRegister() : handleLogin();
    }
  };

  return (
    <Container component="main" maxWidth="lg">
      <Grid container spacing={4} alignItems="center" justifyContent="center">
        {/* Informações sobre o MemoNow */}
        <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'block' } }}>
          <Box sx={{ padding: 3 }}>
            <Typography component="h3" variant="h4" gutterBottom>
              Memonow: memorização para provas de concursos e vestibulares!
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 2 }}>
              O MemoNow é a ferramenta perfeita para transformar seus estudos! Com recursos como:
            </Typography>
            <ul>
              <li><strong>Flashcards:</strong> Revise informações rapidamente e melhore sua memorização.</li>
              <li><strong>Oclusão de Imagens:</strong> Oculte partes das imagens e teste seus conhecimentos visuais.</li>
              <li><strong>Complete o Texto:</strong> Complete lacunas e fixe melhor o conteúdo.</li>
              <li><strong>Categorização:</strong> Organize informações em categorias e domine tópicos complexos.</li>
              <li><strong>Sequenciamento de fases:</strong> Ordene eventos e melhore sua compreensão de processos.</li>
            </ul>
            <Typography variant="body1" sx={{ marginTop: 2 }}>
              Cadastre-se agora e descubra uma maneira dinâmica e eficiente de estudar!
            </Typography>
          </Box>
        </Grid>


        {/* Formulário de Login/Cadastro */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 3,
              border: '1px solid #ddd',
              borderRadius: '8px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}
          >
            <Typography className='tituloLogin' variant="h4" gutterBottom>
              Bem-vindo ao MemoNow!
            </Typography>
            <Typography className='tituloLogin' component="h1" variant="h5">
              {isRegistering ? 'Cadastro' : 'Login'}
            </Typography>
            <Box component="form" sx={{ mt: 1 }} onKeyPress={handleKeyPress}>
              {isRegistering && (
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="nome"
                  label="Name"
                  name="nome"
                  autoComplete="nome"
                  autoFocus
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                />
              )}
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="senha"
                label="Password"
                type="password"
                id="senha"
                autoComplete="current-password"
                value={senha}
                onChange={(e) => setSenha(e.target.value)}
              />
              <Button
                type="button"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={isRegistering ? handleRegister : handleLogin}
              >
                {isRegistering ? 'Cadastrar' : 'Acessar'}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Esqueceu a senha?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2" onClick={() => setIsRegistering(!isRegistering)}>
                    {isRegistering ? 'Já possui uma conta? Acessar' : 'Não possui uma conta? Cadastre-se'}
                  </Link>
                </Grid>
              </Grid>
              {deferredPrompt && (
                <Button
                  fullWidth
                  variant="outlined"
                  sx={{ mt: 2 }}
                  onClick={handleInstallClick}
                >
                  Instalar MemoNow
                </Button>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Snackbar open={!!errorMessage} autoHideDuration={6000} onClose={() => setErrorMessage(null)}>
        <Alert onClose={() => setErrorMessage(null)} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default LoginForm;
