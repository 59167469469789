import React, { useEffect, useState } from 'react';
import { FaThLarge, FaThList, FaCheck, FaEye, FaEraser, FaTrashAlt, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import './CompleteTexto.css';
import { Container } from '../Texto/Texto';

interface CompleteTextoProps {
  id: number;
  title: string;
  texto: string;
}

const CompleteTexto: React.FC<CompleteTextoProps> = ({ id, title, texto }) => {
  const [content, setContent] = useState<string>('');
  const [results, setResults] = useState<string>('');

  useEffect(() => {
    if (texto) {
      parseAndRenderText(texto);
    }
  }, [texto]);

  // Função para embaralhar array (Fisher-Yates)
  const shuffleArray = (array: string[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const parseAndRenderText = (text: string) => {
    const htmlContent = renderText(text);
    setContent(htmlContent);
  };

  const renderText = (text: string) => {
    const regex = /\[\[(.+?)\]\]/g;
    const parts = text.split(regex);
    let htmlContent = '';
  
    parts.forEach((part, index) => {
      if (index % 2 === 0) {
        htmlContent += part;
      } else {
        if (part.includes('|')) {
          const options = part.split('|').map(option => option.replace('*', '').trim());
          const correctOption = part.split('|').find(option => option.includes('*'))?.replace('*', '').trim();
          const shuffledOptions = shuffleArray(options);
  
          htmlContent += `<select class="complete-texto-input dropdown" data-answer="${correctOption?.toLowerCase()}">
            <option value="">Seleciona</option>
            ${shuffledOptions.map(option => `<option value="${option.toLowerCase()}">${option}</option>`).join('')}
          </select>`;
        } else {
          // Separa os sinônimos pelo "/"
          const synonyms = part.split('/').map(synonym => synonym.trim().toLowerCase());
          const correctAnswer = synonyms[0]; // Primeiro sinônimo é mostrado como a resposta principal
  
          htmlContent += `<input type="text" class="complete-texto-input blank" data-synonyms="${synonyms.join(',')}" style="width: ${(correctAnswer.length + 1) * 8}px;" oninput="this.style.width = (this.value.length + 1) * 8 + 'px';">`;
        }
      }
    });
  
    return htmlContent;
  };
  
  const evaluate = () => {
    const container = document.getElementById(`complete-texto-container-${id}`);
    const blanks = container?.querySelectorAll<HTMLInputElement>('.complete-texto-input.blank');
    const dropdowns = container?.querySelectorAll<HTMLSelectElement>('.complete-texto-input.dropdown');
    let correctCount = 0;
    let totalCount = (blanks?.length || 0) + (dropdowns?.length || 0);
  
    blanks?.forEach(blank => {
      const userAnswer = blank.value.toLowerCase().trim();
      const correctSynonyms = blank.dataset.synonyms?.split(',').map(syn => syn.trim()) || [];
  
      blank.classList.add('bold');
      resizeInput(blank);
  
      // Verifica se a resposta está em qualquer um dos sinônimos
      if (correctSynonyms.some(synonym => synonym === userAnswer)) {
        blank.classList.add('complete-texto-correct');
        blank.classList.remove('complete-texto-incorrect');
        correctCount++;
      } else {
        blank.classList.add('complete-texto-incorrect');
        blank.classList.remove('complete-texto-correct');
      }
    });
  
    dropdowns?.forEach(dropdown => {
      const userAnswer = dropdown.value.toLowerCase().trim();
      const correctAnswer = dropdown.dataset.answer;
  
      dropdown.classList.add('bold');
  
      if (userAnswer === correctAnswer) {
        dropdown.classList.add('complete-texto-correct');
        dropdown.classList.remove('complete-texto-incorrect');
        correctCount++;
      } else {
        dropdown.classList.add('complete-texto-incorrect');
        dropdown.classList.remove('complete-texto-correct');
      }
    });
  
    const percentage = ((correctCount / totalCount) * 100).toFixed(2);
    setResults(`<strong>Você acertou ${correctCount}</strong> de ${totalCount} (${percentage}%)`);
  };
  

  const clearIncorrect = () => {
    const container = document.getElementById(`complete-texto-container-${id}`);
    const blanks = container?.querySelectorAll<HTMLInputElement>('.complete-texto-input.blank.complete-texto-incorrect');
    const dropdowns = container?.querySelectorAll<HTMLSelectElement>('.complete-texto-input.dropdown.complete-texto-incorrect');
    blanks?.forEach(blank => {
      blank.value = '';
      blank.classList.remove('complete-texto-incorrect');
      blank.style.width = ''; // Reset width
    });
    dropdowns?.forEach(dropdown => {
      dropdown.value = '';
      dropdown.classList.remove('complete-texto-incorrect');
    });
  };

  const clearAll = () => {
    const container = document.getElementById(`complete-texto-container-${id}`);
    const blanks = container?.querySelectorAll<HTMLInputElement>('.complete-texto-input.blank');
    const dropdowns = container?.querySelectorAll<HTMLSelectElement>('.complete-texto-input.dropdown');
    blanks?.forEach(blank => {
      blank.value = '';
      blank.classList.remove('complete-texto-correct', 'complete-texto-incorrect', 'bold', 'blue');
      blank.style.width = ''; // Reset width
    });
    dropdowns?.forEach(dropdown => {
      dropdown.value = '';
      dropdown.classList.remove('complete-texto-correct', 'complete-texto-incorrect', 'bold', 'blue');
    });
    setResults('');
  };

  const showAnswers = () => {
    const container = document.getElementById(`complete-texto-container-${id}`);
    const blanks = container?.querySelectorAll<HTMLInputElement>('.complete-texto-input.blank');
    const dropdowns = container?.querySelectorAll<HTMLSelectElement>('.complete-texto-input.dropdown');
    blanks?.forEach(blank => {
      const correctSynonyms = blank.dataset.synonyms?.split(',') || [];
      blank.value = correctSynonyms[0] || ''; // Mostra apenas o primeiro sinônimo
      blank.classList.remove('complete-texto-correct', 'complete-texto-incorrect');
      blank.classList.add('blue');
      resizeInput(blank);
    });
    dropdowns?.forEach(dropdown => {
      dropdown.value = dropdown.dataset.answer || '';
      dropdown.classList.remove('complete-texto-correct', 'complete-texto-incorrect');
      dropdown.classList.add('blue');
    });
  };

  const resizeInput = (input: HTMLInputElement) => {
    input.style.width = 'auto';
    const tempSpan = document.createElement('span');
    tempSpan.style.visibility = 'hidden';
    tempSpan.style.position = 'absolute';
    tempSpan.style.whiteSpace = 'nowrap';
    tempSpan.innerText = input.value;
    document.body.appendChild(tempSpan);
    const newWidth = tempSpan.offsetWidth + 10;
    input.style.width = `${newWidth}px`;
    document.body.removeChild(tempSpan);
  };

  return (
    <div className="complete-texto-container" id={`complete-texto-container-${id}`}>
      <Container dangerouslySetInnerHTML={{ __html: content }} />
      <div className="complete-texto-actions">
        <button className="complete-texto-button complete-texto-btn-evaluate" onClick={evaluate}><FaCheck /><span> Avaliar</span></button>
        <button className="complete-texto-button complete-texto-btn-clear" onClick={clearIncorrect}><FaEraser /><span> Limpar Incorretas</span></button>
        <button className="complete-texto-button complete-texto-btn-clear-all" onClick={clearAll}><FaTrashAlt /><span> Limpar Tudo</span></button>
        <button className="complete-texto-button complete-texto-btn-show" onClick={showAnswers}><FaEye /><span> Ver respostas</span></button>
      </div>
      <div id= {`${results && results!==''?'results':'results-no'}`} dangerouslySetInnerHTML={{ __html: results }}></div>
    </div>
  );
};

export default CompleteTexto;
