import React, { useState, useEffect, useRef } from 'react';
import './EstudoPersonalizado.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList, faCalendarCheck, faCalendarAlt, faChartLine, faTasks, faBook, faChartPie } from '@fortawesome/free-solid-svg-icons';
import MenuPastas from './MenuPastas';
import Pagina from './Pagina';
import IconButton from '@mui/material/IconButton';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import RevisaoEspacadaPage from './RevisaoEspacadaPage'; // Importar o novo componente
import EstatisticasPage from './EstatisticasPage';

interface EstudoPersonalizadoProps {
  userId: number;
  cursoId: number;
}

const EstudoPersonalizado: React.FC<EstudoPersonalizadoProps> = ({ userId, cursoId }) => {
  const [activeTab, setActiveTab] = useState<string>('minhas-notas');
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(true);
  const [selectedFolderId, setSelectedFolderId] = useState<number | null>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const toggleMenuVisibility = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  const showMenuPastas = () => {
    setIsMenuVisible(true);
  };

  // Detecta clique fora do menu
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        //setIsMenuVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    if (tab === 'minhas-notas') {
      showMenuPastas();
    }
  };

  return (
    <div className="estudo-personalizado">     
      <div className="tab-content">
        {activeTab === 'minhas-notas' && (
          <div className="conteudo-notas">
            <div ref={menuRef}>
              <MenuPastas onSelectPage={setSelectedFolderId} cursoId={cursoId} isVisible={isMenuVisible} toggleVisibility={toggleMenuVisibility} usuarioId={userId} />
            </div>
            <div className={`pagina-conteudo ${isMenuVisible ? '' : 'full-width'}`}>
              {selectedFolderId !== null && <Pagina usuarioId={userId} pageId={selectedFolderId} />}
            </div>
          </div>
        )}
        {activeTab === 'revisao-espacada' && (
          <RevisaoEspacadaPage usuarioId={userId} cursoId={cursoId} />
        )}
        {activeTab === 'revisao-periodica' && (
          <div>Conteúdo de Revisão Periódica</div>
        )}
        {activeTab === 'planejamento' && (
          <div>Conteúdo de Planejamento</div>
        )}
        {activeTab === 'estatisticas' && (
          <EstatisticasPage usuarioId={userId} cursoId={cursoId} />
        )}
      </div>
      <div className="tab-switcher">
        <IconButton className={`menu-toggle ${isMenuVisible ? '' : 'hidden'} ${activeTab === 'minhas-notas' ? '' : 'oculto'}`} onClick={toggleMenuVisibility}>
          {isMenuVisible ? <KeyboardDoubleArrowLeftIcon className='botao-menu-toggle' /> : <KeyboardDoubleArrowRightIcon className='botao-menu-toggle' />}
        </IconButton>
        <button
          className={`tab-button ${activeTab === 'minhas-notas' ? 'active' : ''}`}
          onClick={() => handleTabChange('minhas-notas')}
        >
          <FontAwesomeIcon className='icones-secoes' icon={faBook} />
          <div className="tooltip">Estudo</div>
        </button>
        <button
          className={`tab-button ${activeTab === 'revisao-espacada' ? 'active' : ''}`}
          onClick={() => handleTabChange('revisao-espacada')}
        >
          <FontAwesomeIcon className='icones-secoes' icon={faCalendarCheck} />
          <div className="tooltip">Revisão do Dia</div>
        </button>
        <button
          className={`tab-button ${activeTab === 'estatisticas' ? 'active' : ''}`}
          onClick={() => handleTabChange('estatisticas')}
        >
          <FontAwesomeIcon className='icones-secoes' icon={faChartPie} />
          <div className="tooltip">Estatísticas</div>
        </button>
      </div>
    </div>
  );
};

export default EstudoPersonalizado;
