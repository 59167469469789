import React, { useEffect, useState } from 'react';
import './Ordenacao.css';
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd';
import { CheckCircleOutline, Replay } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

interface Stage {
  order: number;
  value: string;
}

interface OrdenacaoProps {
  data: {
    question: string;
    stages: Stage[];
    comment?: string;
  };
}

const Ordenacao: React.FC<OrdenacaoProps> = ({ data }) => {
  const [stages, setStages] = useState<Stage[]>([]);
  const [result, setResult] = useState('');
  const [showComment, setShowComment] = useState(false); // Controla a exibição do comentário


  useEffect(() => {
    if (data && data.stages) {
      setStages(shuffle([...data.stages]));
    }
  }, [data]);

  const shuffle = (array: Stage[]): Stage[] => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const reorderedStages = Array.from(stages);
    const [movedStage] = reorderedStages.splice(source.index, 1);
    reorderedStages.splice(destination.index, 0, movedStage);

    setStages(reorderedStages);
  };

  const checkOrder = () => {
    let correctCount = 0;
    const items = document.querySelectorAll('.ordenacao-item');

    items.forEach((item, index) => {
      const order = parseInt(item.getAttribute('data-order') || '0');
      if (order === index + 1) {
        item.classList.add('ordenacao-correct');
        item.classList.remove('ordenacao-incorrect');
        correctCount++;
      } else {
        item.classList.add('ordenacao-incorrect');
        item.classList.remove('ordenacao-correct');
      }
    });

    const score = (correctCount / stages.length) * 100;
    setResult(`Você acertou ${correctCount} de ${stages.length} (${score.toFixed(2)}%)`);
  };

  const restartGame = () => {
    setResult('');
    setStages(shuffle([...data.stages]));

    const items = document.querySelectorAll('.ordenacao-item');
    items.forEach(item => {
      item.classList.remove('ordenacao-correct', 'ordenacao-incorrect');
    });
  };

  const toggleComment = () => {
    setShowComment(!showComment);
  };

  return (
    <div className="ordenacao-container">
      <h3>{data.question}</h3>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="stages">
          {(provided) => (
            <ul className="ordenacao-list" {...provided.droppableProps} ref={provided.innerRef}>
              {stages.map((stage, index) => (
                <Draggable key={stage.value} draggableId={stage.value} index={index}>
                  {(provided, snapshot) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={`ordenacao-item ${snapshot.isDragging ? 'ordenacao-dragging' : ''}`}
                      data-order={stage.order}
                    >
                      {stage.value}
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
      <div className="ordenacao-button-container">
        <button className="ordenacao-button" onClick={checkOrder}>
          <CheckCircleOutline style={{ marginRight: 8 }} />
          Verificar
        </button>
        <button className="ordenacao-button" onClick={restartGame}>
          <Replay style={{ marginRight: 8 }} />
          Reiniciar
        </button>
      </div>
      <div className="ordenacao-result">{result}</div>
      {data.comment &&  data.comment !== '<p><br></p>' && (
        <>
          <div className="accordion" onClick={toggleComment}>
            <span>{showComment ? 'Ocultar Comentário' : 'Saiba mais'}</span>
            {showComment ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
          </div>
          {showComment && <div className="ordenacao-comment" dangerouslySetInnerHTML={{ __html: data.comment }} />}
        </>
      )}
    </div>
  );
};

export default Ordenacao;
