import React, { useState, useEffect } from 'react';
import { List, IconButton, Menu, MenuItem, Button, InputBase, Divider } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import FolderIcon from '@mui/icons-material/Folder';
import DescriptionIcon from '@mui/icons-material/Description';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import SearchIcon from '@mui/icons-material/Search';
import {
  fetchPastas,
  fetchPastasPorCurso,
  createPasta,
  updatePasta,
  deletePasta,
  createPagina,
  updatePagina,
  deletePagina,
  fetchTipoUsuarioNoCurso,
} from '../services/api';
import MenuItemPastas from './MenuItemPastas';
import AddPastaModal from './AddPastaModal';
import AddPaginaModal from './AddPaginaModal';
import ConfirmDialog from './ConfirmDialog'; // Novo componente de confirmação
import { useAuth } from '../context/AuthContext';
import './MenuPastas.css';

interface Pagina {
  id: number;
  titulo: string;
  pastaId: number;
}

interface Pasta {
  id: number;
  nome: string;
  parentId?: number;
  pastaPai?: { id: number; nome: string } | null;  // Alterado para refletir a resposta do backend
  subPastas?: Pasta[];
  paginas?: Pagina[];
}

interface MenuPastasProps {
  onSelectPage: (pageId: number) => void;
  isVisible: boolean;
  toggleVisibility: () => void;
  cursoId: number;
  usuarioId: number;
}

const MenuPastas: React.FC<MenuPastasProps> = ({ onSelectPage, isVisible, toggleVisibility, cursoId, usuarioId }) => {
  const { user } = useAuth();
  const [pastas, setPastas] = useState<Pasta[]>([]);
  const [openItems, setOpenItems] = useState<{ [key: string]: boolean }>({});
  const [modalOpen, setModalOpen] = useState(false);
  const [paginaModalOpen, setPaginaModalOpen] = useState(false);
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
    id: number;
    type: 'pasta' | 'pagina' | null;
  }>({ mouseX: 0, mouseY: 0, id: -1, type: null });
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false); // Estado para o modal de confirmação
  const [confirmDialogAction, setConfirmDialogAction] = useState<() => void>(() => {}); // Ação de confirmação
  const [parentId, setParentId] = useState<number | undefined>(undefined);
  const [renameValue, setRenameValue] = useState<string>(''); // Estado para armazenar o valor a ser renomeado
  const [searchTerm, setSearchTerm] = useState<string>(''); // Estado para o termo de busca
  const [filteredResults, setFilteredResults] = useState<Pasta[]>([]); // Estado para os resultados filtrados
  const isMobile = window.innerWidth <= 1024; // Considera dispositivos móveis como telas com largura <= 768px
  const [lastInsertedName, setLastInsertedName] = useState<string | null>(null); // Armazena o nome do último item inserido
  const [tipoUsuario, setTipoUsuario] = useState<'aluno' | 'professor' | 'admin' | null>(null); // Novo estado para armazenar o tipo do usuário no curso


  const fetchData = async () => {
    const pastaResponse = await fetchPastasPorCurso(cursoId);
    const pastasData: Pasta[] = pastaResponse.data;
    setPastas(pastasData); // Atualiza pastas sem alterar o estado de openItems

    const response = await fetchTipoUsuarioNoCurso(cursoId, usuarioId);
    setTipoUsuario(response.data.tipoUsuario);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (searchTerm === '') {
      setFilteredResults([]);
      setOpenItems({});
    } else {
      const results: Pasta[] = [];
      const openItemsState: { [key: string]: boolean } = {};
      const searchLower = searchTerm.toLowerCase();
  
      const searchItems = (pastas: Pasta[], parentPath: string[] = []) => {
        return pastas.reduce((acc: Pasta[], pasta) => {
          let folderMatches = pasta.nome.toLowerCase().includes(searchLower);
          let paginasMatches = false;
          let subPastasMatches = false;
  
          const matchedPages = pasta.paginas?.filter(pagina => 
            pagina.titulo.toLowerCase().includes(searchLower)
          ) || [];
  
          paginasMatches = matchedPages.length > 0;
  
          const matchedSubPastas = searchItems(pasta.subPastas || [], [...parentPath, pasta.nome]);
          subPastasMatches = matchedSubPastas.length > 0;
  
          if (folderMatches || paginasMatches || subPastasMatches) {
            // Expande a pasta correspondente
            openItemsState[`pasta-${pasta.id}`] = true; 
            // Adiciona subpastas e páginas encontradas
            acc.push({
              ...pasta,
              subPastas: matchedSubPastas.length > 0 ? pasta.subPastas : [],
              paginas: matchedPages.length > 0 ? matchedPages : [],
            });
          } else {
            // Expande a pasta se alguma subpasta foi expandida
            openItemsState[`pasta-${pasta.id}`] = acc.some(item => item.id === pasta.id);
          }
  
          return acc;
        }, []);
      };
  
      const filtered = searchItems(pastas);
      setFilteredResults(filtered);
      setOpenItems(prevState => ({ ...prevState, ...openItemsState }));
    }
  }, [searchTerm, pastas]);
  
  const expandToNewItem = (expandId: number) => {
    setOpenItems((prevOpenItems) => {
      const newOpenItems = { ...prevOpenItems };
      expandParentFolders(pastas, expandId, newOpenItems); // Expande a pasta pai ou a nova pasta
      return newOpenItems;
    });
  };

// Função auxiliar para expandir pastas até o novo item
// Função auxiliar para expandir pastas pais até o novo item
const expandParentFolders = (pastas: Pasta[], targetId: number, openState: { [key: string]: boolean }) => {
  const searchAndExpand = (folders: Pasta[]): boolean => {
    for (const folder of folders) {
      const folderPath = `pasta-${folder.id}`;

      if (folder.id === targetId) {
        openState[folderPath] = true; // Expande a pasta se encontrar o item
        return true;
      }

      if (folder.subPastas && searchAndExpand(folder.subPastas)) {
        openState[folderPath] = true; // Expande o caminho até o item
        return true;
      }
    }
    return false;
  };

  searchAndExpand(pastas);
};

// Função para buscar e expandir a pasta/página pelo nome
const expandByName = (pastas: Pasta[], name: string) => {
  const searchAndExpand = (folders: Pasta[]): boolean => {
    for (const folder of folders) {
      const folderPath = `pasta-${folder.id}`;
      
      if (folder.nome === name) {
        setOpenItems((prevOpenItems) => ({ ...prevOpenItems, [folderPath]: true }));
        return true;
      }

      if (folder.subPastas && searchAndExpand(folder.subPastas)) {
        setOpenItems((prevOpenItems) => ({ ...prevOpenItems, [folderPath]: true }));
        return true;
      }

      if (folder.paginas?.some((page) => page.titulo === name)) {
        setOpenItems((prevOpenItems) => ({ ...prevOpenItems, [folderPath]: true }));
        return true;
      }
    }
    return false;
  };

  searchAndExpand(pastas);
};

// useEffect para procurar a última pasta ou página inserida
useEffect(() => {
  if (lastInsertedName) {
    expandByName(pastas, lastInsertedName); // Expande o item baseado no nome
  }
}, [pastas, lastInsertedName]); // Executa sempre que as pastas ou o último item inserido mudar


  const handleMenuItemClick = (id: number, type: string) => {
    const newOpenItems = { ...openItems };
    newOpenItems[`${type}-${id}`] = !openItems[`${type}-${id}`];
    setOpenItems(newOpenItems);
  };

  const handleSelectPage = (pageId: number) => {
    onSelectPage(pageId);
    if (isMobile) {
      toggleVisibility(); // Encolhe o menu se estiver em um dispositivo móvel
    }
  };

// Adicionar nova pasta e salvar o nome
const handleAddPasta = async (nome: string) => {
  if (!user) return;
  const response = await createPasta(nome, parentId, cursoId);
  setLastInsertedName(nome); // Armazena o nome da nova pasta
  
  setModalOpen(false); // Fecha o modal
  fetchData(); // Recarrega os dados da pasta
};

// Adicionar nova página e salvar o nome
const handleAddPagina = async (titulo: string, parentId: number) => {
  if (!user) return;
  const response = await createPagina(titulo, parentId, cursoId);
  setLastInsertedName(titulo); // Armazena o nome da nova página
  
  setPaginaModalOpen(false); // Fecha o modal
  fetchData(); // Recarrega os dados da pasta
};

  const handleContextMenu = (event: React.MouseEvent, id: number, type: 'pasta' | 'pagina') => {
    event.preventDefault();
    setContextMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      id,
      type,
    });
  };

  const handleContextMenuClose = () => {
    setContextMenu({ mouseX: 0, mouseY: 0, id: -1, type: null });
  };

  const findNomeAtual = (id: number, type: 'pasta' | 'pagina', pastas: Pasta[]): string | undefined => {
    for (const pasta of pastas) {
      if (type === 'pasta' && pasta.id === id) {
        return pasta.nome;
      }
      if (type === 'pagina') {
        const pagina = pasta.paginas?.find(p => p.id === id);
        if (pagina) {
          return pagina.titulo;
        }
      }
      if (pasta.subPastas) {
        const nomeAtual = findNomeAtual(id, type, pasta.subPastas);
        if (nomeAtual) {
          return nomeAtual;
        }
      }
    }
    return undefined;
  };

  const handleContextMenuAction = async (action: string) => {
    handleContextMenuClose();
    const { id, type } = contextMenu;

    if (!type) return;

    if (action === 'nova_pasta') {
      setParentId(id);
      setModalOpen(true);
    } else if (action === 'nova_pagina') {
      setParentId(id);
      setPaginaModalOpen(true);
    } else if (action === 'renomear') {
      const nomeAtual = findNomeAtual(id, type, pastas);
      const novoNome = prompt(`Digite o novo nome do ${type}:`, nomeAtual);
      if (novoNome) {
        if (type === 'pasta') {
          await updatePasta(id, novoNome);
        } else {
          await updatePagina(id, novoNome);
        }
        fetchData(); // Recarrega os dados das pastas
  
        // Expande até a pasta ou página renomeada
        setLastInsertedName(novoNome); 
      }
    } else if (action === 'excluir') {
      setConfirmDialogAction(() => async () => {
        let parentPastaNome = '';
  
        if (type === 'pasta') {
          const pasta = findPastaById(pastas, id); // Use a nova função para buscar a pasta
          if (pasta) {
            const parentPasta = pasta.pastaPai ? pasta.pastaPai : undefined;
            parentPastaNome = parentPasta ? parentPasta.nome : '';
            await deletePasta(id);
          }
        } else {
          const parentFolder = pastas.find((p) =>
            p.paginas?.some((pagina) => pagina.id === id)
          );
          parentPastaNome = parentFolder ? parentFolder.nome : '';
          await deletePagina(id);
        }
  
        fetchData(); // Recarrega os dados das pastas
  
        if (parentPastaNome) {
          setLastInsertedName(parentPastaNome); // Expande a pasta pai pelo nome
        }
  
        setConfirmDialogOpen(false); // Fecha o diálogo de confirmação após a exclusão
      });
      setConfirmDialogOpen(true);
    }
  };

  const findPastaById = (pastas: Pasta[], id: number): Pasta | undefined => {
    for (const pasta of pastas) {
      if (pasta.id === id) {
        return pasta; // Encontrou a pasta
      }
      if (pasta.subPastas) {
        const found = findPastaById(pasta.subPastas, id); // Busca nas subpastas
        if (found) return found;
      }
    }
    return undefined; // Não encontrou
  };
  
  const renderFolder = (folder: Pasta, level: number = 0) => {
    // Ordena as subpastas em ordem alfabética
    const sortedSubPastas = folder.subPastas?.sort((a, b) => a.nome.localeCompare(b.nome)) || [];
    
    // Ordena as páginas em ordem alfabética
    const sortedPaginas = folder.paginas?.sort((a, b) => a.titulo.localeCompare(b.titulo)) || [];
  
    return (
      <MenuItemPastas
        key={folder.id}
        name={folder.nome}
        id={folder.id}
        type="pasta"
        isOpen={openItems[`pasta-${folder.id}`]}
        onClick={() => handleMenuItemClick(folder.id, 'pasta')}
        onContextMenu={(event) => handleContextMenu(event, folder.id, 'pasta')}
        icon={<FolderIcon />}
        level={level}
      >
        {openItems[`pasta-${folder.id}`] && (
          <>
            {/* Renderiza subpastas primeiro */}
            {sortedSubPastas.length > 0 && sortedSubPastas.map((subFolder) => (
              <List key={subFolder.id} component="div" disablePadding>
                {renderFolder(subFolder, level + 1)}
              </List>
            ))}
            
            {/* Renderiza páginas em seguida */}
            {sortedPaginas.length > 0 && sortedPaginas.map((page) => (
              <MenuItemPastas
                key={page.id}
                name={page.titulo}
                id={page.id}
                type="pagina"
                onClick={() => handleSelectPage(page.id)}
                onContextMenu={(event) => handleContextMenu(event, page.id, 'pagina')}
                icon={<DescriptionIcon />}
                level={level + 1}
              />
            ))}
          </>
        )}
      </MenuItemPastas>
    );
  };
  
  
  const renderExpandedFolder = (folder: Pasta, level: number = 0) => (
    <MenuItemPastas
      key={folder.id}
      name={folder.nome}
      id={folder.id}
      type="pasta"
      isOpen={true}
      onClick={() => handleMenuItemClick(folder.id, 'pasta')}
      onContextMenu={(event) => handleContextMenu(event, folder.id, 'pasta')}
      icon={<FolderIcon />}
      level={level}
    >
      {folder.paginas &&
        folder.paginas.map((page) => (
          <MenuItemPastas
            key={page.id}
            name={page.titulo}
            id={page.id}
            type="pagina"
            onClick={() => onSelectPage(page.id)}
            onContextMenu={(event) => handleContextMenu(event, page.id, 'pagina')}
            icon={<DescriptionIcon />}
            level={level + 1}
          />
        ))}
      {folder.subPastas &&
        folder.subPastas.map((subFolder) => (
          <List key={subFolder.id} component="div" disablePadding>
            {renderExpandedFolder(subFolder, level + 1)}
          </List>
        ))}
    </MenuItemPastas>
  );

  return (
    <div className={`menu-pastas-container ${isVisible ? '' : 'hidden'}`}>
      <div className="menu-pastas-header">
        <div className='menu-toggle-oculto'>
          <IconButton onClick={toggleVisibility} color="primary" aria-label="toggle menu visibility">
            {isVisible ? <KeyboardDoubleArrowLeftIcon /> : <KeyboardDoubleArrowRightIcon />}
          </IconButton>
        </div>
        <div>
          {isVisible && tipoUsuario !== 'aluno' &&(
            <IconButton onClick={() => setModalOpen(true)} color="primary" aria-label="add pasta">
              <AddIcon /> <span className="menu-pastas-add-folder-label">Adicionar Pasta</span>
            </IconButton>
          )}
        </div>
      </div>
      {isVisible && (
        <>
          <div className="menu-search">
            <InputBase
              placeholder="Pesquisar..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              startAdornment={<SearchIcon />}
              fullWidth
              sx={{ padding: '10px' }}
            />
          </div>
          <Divider />
          <List>
            {searchTerm && filteredResults.length > 0 ? (
              filteredResults
                .sort((a, b) => a.nome.localeCompare(b.nome))  // Ordena as pastas na raiz
                .map(folder => (
                  <div key={folder.id}>
                    {renderFolder(folder, 0)}
                  </div>
                ))
            ) : (
              pastas
                .sort((a, b) => a.nome.localeCompare(b.nome))  // Ordena as pastas na raiz
                .map((folder) => renderFolder(folder))
            )}
          </List>


        </>
      )}
      <AddPastaModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onSave={(nome) => handleAddPasta(nome)}
      />
      <AddPaginaModal
        open={paginaModalOpen}
        onClose={() => setPaginaModalOpen(false)}
        onSave={(titulo) => handleAddPagina(titulo, parentId!)}
      />
      <ConfirmDialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        onConfirm={confirmDialogAction}
      />
      <Menu
        keepMounted
        open={contextMenu.type !== null}
        onClose={handleContextMenuClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu.mouseY !== null && contextMenu.mouseX !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        {contextMenu.type === 'pasta' && tipoUsuario !== 'aluno' && [
          <MenuItem key="nova_pasta" onClick={() => handleContextMenuAction('nova_pasta')}>Nova Pasta</MenuItem>,
          <MenuItem key="nova_pagina" onClick={() => handleContextMenuAction('nova_pagina')}>Nova Página</MenuItem>
        ]}
         {tipoUsuario !== 'aluno' && (
          <>
            <MenuItem onClick={() => handleContextMenuAction('renomear')}>Renomear</MenuItem>
            <MenuItem onClick={() => handleContextMenuAction('excluir')}>Excluir</MenuItem>
          </>
        )}
      </Menu>        
    </div>
  );
};

export default MenuPastas;
