import React, { useState, useEffect } from 'react';
import './ItensSelecao.css';
import { Alert, Snackbar } from '@mui/material';
import { CheckCircleOutline, Replay } from '@mui/icons-material';
import { FaThLarge, FaThList, FaCheck, FaEye, FaEraser, FaTrashAlt, FaChevronDown, FaChevronUp } from 'react-icons/fa';

interface ItensSelecaoProps {
  data: {
    question: string;
    correctItems: string[];
    incorrectItems: string[];
    comment?: string;
  };
}

const ItensSelecao: React.FC<ItensSelecaoProps> = ({ data }) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [result, setResult] = useState<string | null>(null);
  const [isEvaluated, setIsEvaluated] = useState(false);
  const [showAnswers, setShowAnswers] = useState(false); // Estado para o botão "Mostrar Respostas"
  const [showComment, setShowComment] = useState(false);
  const [erroMaximoItens, setErroMaximoItens] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const [shuffledItems, setShuffledItems] = useState<string[]>([]); // Novo estado para os itens embaralhados

  const allItems = [...data.correctItems, ...data.incorrectItems];
  const maxCorrectSelections = data.correctItems.length;

  // Função para embaralhar os itens usando o algoritmo Fisher-Yates Shuffle
  const shuffleItems = (items: string[]) => {
    const shuffled = [...items];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  // Embaralha os itens ao carregar o componente
  useEffect(() => {
    setShuffledItems(shuffleItems(allItems));
  }, [data]);

  const handleItemClick = (item: string) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter((i) => i !== item));
    } else if (selectedItems.length < maxCorrectSelections) {
      setSelectedItems([...selectedItems, item]);
    } else {
      setErroMaximoItens(true);
      showError(`Selecione no máximo ${data.correctItems.length} itens.`);
    }

    setIsEvaluated(false);
    setShowAnswers(false); // Reseta a exibição das respostas
    setResult(null);
  };

  const showError = (message: string) => {
    setErrorMessage(message);
    setOpen(true);

    // Ocultar a mensagem após 3 segundos
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  };

  const checkResults = () => {
    setIsEvaluated(true);
    const correctSelections = selectedItems.filter((item) => data.correctItems.includes(item)).length;
    const totalCorrect = data.correctItems.length;
    setResult(`Você acertou ${correctSelections} de ${totalCorrect}`);
  };

  const showCorrectAnswers = () => {
    setShowAnswers(true);
    setIsEvaluated(false);
    setResult(null);
  };

  const resetSelections = () => {
    setSelectedItems([]);
    setResult(null);
    setIsEvaluated(false);
    setShowAnswers(false);
    setShuffledItems(shuffleItems(allItems)); // Embaralha novamente ao reiniciar
  };

  const getItemClass = (item: string) => {
    if (showAnswers) {
      return data.correctItems.includes(item) ? 'correct' : 'incorrect';
    }
    if (!isEvaluated) {
      return selectedItems.includes(item) ? 'selected' : '';
    }
    if (selectedItems.includes(item)) {
      return data.correctItems.includes(item) ? 'correct' : 'incorrect';
    }
    return '';
  };

  const toggleComment = () => {
    setShowComment(!showComment);
  };

  return (
    <div className="itens-selecao-container">
      <h3>{`${data.question} (${data.correctItems.length})`}</h3>
      <div className="itens-selecao-items">
        {shuffledItems.map((item, index) => (
          <div
            key={index}
            className={`itens-selecao-item ${getItemClass(item)}`}
            onClick={() => handleItemClick(item)}
          >
            {item}
          </div>
        ))}
      </div>
      <div className='itens-selecao-buttons'>
      <button className="itens-selecao-button" onClick={checkResults}>
          <FaCheck /> <span>Avaliar</span>
        </button>
        <button className="itens-selecao-button" onClick={showCorrectAnswers}>
          <FaEye /> <span>Mostrar Respostas</span>
        </button>
        <button className="itens-selecao-button" onClick={resetSelections}>
         <Replay /> <span>Reiniciar</span>
        </button>
      </div>
      {result && <p>{result}</p>}
      <Snackbar open={open} onClose={() => setOpen(false)} autoHideDuration={3000}  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}  sx={{ top: '80px' }} >
        <Alert onClose={() => setOpen(false)} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
      {data.comment && (
        <>
          <div className="accordion" onClick={toggleComment}>
            <span>{showComment ? 'Ocultar Comentário' : 'Saiba mais'}</span>
          </div>
          {showComment && <div className="itens-selecao-comment" dangerouslySetInnerHTML={{ __html: data.comment }} />}
        </>
      )}
    </div>
  );
};

export default ItensSelecao;
