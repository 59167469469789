import React, { useState, useEffect } from 'react';
import { fetchTodosCursos, createCurso, updateCurso, deleteCurso, fetchUsuarios } from '../services/api'; // Atualize a API
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Select, MenuItem } from '@mui/material';

const CursosAdmin: React.FC = () => {
  const [cursos, setCursos] = useState([]); // Estado para armazenar os cursos
  const [usuarios, setUsuarios] = useState([]); // Estado para armazenar os professores (usuários)
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCurso, setSelectedCurso] = useState<any>(null); // Estado para o curso selecionado para edição
  const [nomeCurso, setNomeCurso] = useState(''); // Estado para o nome do curso
  const [descricao, setDescricao] = useState(''); // Estado para a descrição do curso
  const [ementaPdfUrl, setEmentaPdfUrl] = useState(''); // Estado para o URL da ementa (PDF)
  const [professorId, setProfessorId] = useState(''); // Estado para o ID do professor selecionado

  useEffect(() => {
    loadCursos(); // Carregar cursos na montagem do componente
    loadUsuarios(); // Carregar lista de professores (usuários)
  }, []);

  // Função para carregar todos os cursos
  const loadCursos = async () => {
    const response = await fetchTodosCursos();
    setCursos(response.data);
  };

  // Função para carregar os usuários (para seleção do professor)
  const loadUsuarios = async () => {
    const response = await fetchUsuarios();
    setUsuarios(response.data);
  };

  // Função para iniciar o diálogo de adição de curso
  const handleAddCurso = () => {
    setSelectedCurso(null);
    setNomeCurso('');
    setDescricao(''); // Limpar o campo descrição
    setEmentaPdfUrl(''); // Limpar o campo ementa
    setProfessorId(''); // Limpar a seleção de professor
    setOpenDialog(true);
  };

  // Função para iniciar o diálogo de edição de curso
  const handleEditCurso = (curso: any) => {
    setSelectedCurso(curso);
    setNomeCurso(curso.nome);
    setDescricao(curso.descricao || ''); // Preencher descrição se disponível
    setEmentaPdfUrl(curso.ementaPdfUrl || ''); // Preencher URL da ementa se disponível
    setProfessorId(curso.professor?.id || null); // Preencher ID do professor se disponível
    setOpenDialog(true);
  };

  // Função para salvar (criar ou atualizar) o curso
  const handleSaveCurso = async () => {
    const cursoData = {
      nome: nomeCurso,
      descricao,
      ementaPdfUrl,
      professor: professorId,
    };

    if (selectedCurso) {
      await updateCurso(selectedCurso.id, cursoData);
    } else {
      await createCurso(cursoData);
    }

    setOpenDialog(false);
    loadCursos(); // Recarregar os cursos após salvar
  };

  // Função para excluir um curso
  const handleDeleteCurso = async (cursoId: number) => {
    await deleteCurso(cursoId);
    loadCursos(); // Recarregar os cursos após a exclusão
  };

  return (
    <div className="cursos-admin">
      <Button onClick={handleAddCurso}>Adicionar Curso</Button>
      <ul>
        {cursos.map((curso) => (
          <li key={curso.id}>
            {curso.nome}
            <Button onClick={() => handleEditCurso(curso)}>Editar</Button>
            <Button onClick={() => handleDeleteCurso(curso.id)}>Excluir</Button>
          </li>
        ))}
      </ul>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>{selectedCurso ? 'Editar Curso' : 'Adicionar Curso'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Nome do Curso"
            value={nomeCurso}
            onChange={(e) => setNomeCurso(e.target.value)}
            fullWidth
          />
          <TextField
            label="Descrição"
            value={descricao}
            onChange={(e) => setDescricao(e.target.value)}
            fullWidth
          />
          <TextField
            label="URL da Ementa (PDF)"
            value={ementaPdfUrl}
            onChange={(e) => setEmentaPdfUrl(e.target.value)}
            fullWidth
          />
          <Select
            label="Professor"
            value={professorId}
            onChange={(e) => setProfessorId(e.target.value)}
            fullWidth
          >
            {usuarios.map((usuario) => (
              <MenuItem key={usuario.id} value={usuario.id}>
                {usuario.nome}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancelar</Button>
          <Button onClick={handleSaveCurso}>Salvar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CursosAdmin;
