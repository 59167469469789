import React, { useState, useEffect, useRef } from 'react';
import './ImageOcclusionPorClique.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faRedo, faChevronDown, faChevronUp  } from '@fortawesome/free-solid-svg-icons';

interface Occlusion {
  top: number;
  left: number;
  width: number;
  height: number;
  label: string;
}

interface ImageOcclusionPorCliqueProps {
  data: {
    imageUrl: string;
    occlusions: Occlusion[];
    imageWidth: number;
    imageHeight: number;
    withText: boolean;
    comment?: string;
    title?: string;
  } | undefined;
}

const ImageOcclusionPorClique: React.FC<ImageOcclusionPorCliqueProps> = ({ data }) => {
  const [revealed, setRevealed] = useState<boolean[]>([]);
  const [showOcclusions, setShowOcclusions] = useState(true);
  const [showComment, setShowComment] = useState(false);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const [imageDimensions, setImageDimensions] = useState<{ width: number, height: number }>({ width: 800, height: 600 });
  const containerRef = useRef<HTMLDivElement | null>(null);


  useEffect(() => {
    if (data?.occlusions) {
      setRevealed(Array(data.occlusions.length).fill(false));
    }
  }, [data]);

  const updateImageDimensions = () => {
    if (imageRef.current && containerRef.current) {
      setImageDimensions({
        width: imageRef.current.offsetWidth,
        height: imageRef.current.offsetHeight,
      });
    }
  };  

  useEffect(() => {
    updateImageDimensions();
    window.addEventListener('resize', updateImageDimensions);
    return () => {
      window.removeEventListener('resize', updateImageDimensions);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      updateImageDimensions();
    };
  
    const resizeObserver = new ResizeObserver(handleResize);
  
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }
  
    window.addEventListener('resize', handleResize);
  
    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
      window.removeEventListener('resize', handleResize);
    };
  }, [data]);
  

  useEffect(() => {
    if (imageRef.current) {
      const checkIfLoaded = () => {
        if (imageRef.current?.complete && imageRef.current?.naturalHeight !== 0) {
          updateImageDimensions();
        } else {
          setTimeout(checkIfLoaded, 100);
        }
      };
      checkIfLoaded();
    }
  }, [data]);

  if (!data) {
    return <div>Dados insuficientes para renderizar o componente.</div>;
  }

  const { imageUrl, occlusions, imageWidth, imageHeight, withText, comment } = data;

  const toggleReveal = (index: number) => {
    const newRevealed = [...revealed];
    newRevealed[index] = !newRevealed[index];
    setRevealed(newRevealed);
  };

  const resetOcclusions = () => {
    setRevealed(Array(data.occlusions.length).fill(false));
  };

  const toggleOcclusions = () => {
    setShowOcclusions(!showOcclusions);
  };

  const toggleComment = () => {
    setShowComment(!showComment);
  };

  const scaleFactorWidth = imageDimensions.width / imageWidth;
  const scaleFactorHeight = imageDimensions.height / imageHeight;

  return (
    <div className="image-occlusion-por-clique-container" ref={containerRef}>
      {data.title && <h3>{data.title}</h3>}
      <div className="image-occlusion-por-clique-image-container">
        <img
          ref={imageRef}
          src={imageUrl}
          alt="Occlusion"
          className="image-occlusion-por-clique-full"
        />
        {showOcclusions && occlusions.map((occlusion, index) => (
          <div
            key={index}
            className="image-occlusion-por-clique-occlusion"
            style={{
              top: `${occlusion.top * scaleFactorHeight}px`,
              left: `${occlusion.left * scaleFactorWidth}px`,
              width: `${occlusion.width * scaleFactorWidth}px`,
              height: `${occlusion.height * scaleFactorHeight}px`,
              display: revealed[index] && !withText ? 'none' : 'flex',
            }}
            onClick={() => toggleReveal(index)}
          >
            {revealed[index] && withText && <span className="image-occlusion-por-clique-text">{occlusion.label}</span>}
          </div>
        ))}
      </div>
      <div className="image-occlusion-por-clique-buttons">
        <button onClick={resetOcclusions} className="image-occlusion-por-clique-button">
          <FontAwesomeIcon icon={faRedo} />
        </button>
        <button onClick={toggleOcclusions} className="image-occlusion-por-clique-button">
          <FontAwesomeIcon icon={showOcclusions ? faEyeSlash : faEye} />
          <span>{showOcclusions ? 'Mostrar Imagem' : 'Mostrar Occlusions'}</span>
        </button>
      </div>
      {comment &&  comment !== '<p><br></p>' && (
        <>
          <div className="accordion" onClick={toggleComment}>
            <span>{showComment ? 'Ocultar Comentário' : 'Saiba mais'}</span>
            {showComment ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
          </div>
          {showComment && (
            <div
              className="image-occlusion-comment"
              dangerouslySetInnerHTML={{ __html: comment }} // Renderiza o HTML do comentário
            />
          )}

        </>
      )}
    </div>
  );
};

export default ImageOcclusionPorClique;
