import axios from 'axios';
import { saveComponenteRevisao, getComponentesRevisao, syncWithServer } from './indexedDBService';


const apiUrl = process.env.REACT_APP_API_URL;
console.log('API URL:', apiUrl);

const api = axios.create({
  baseURL: apiUrl,
  timeout: 300000,
});

// Interceptor para adicionar o token de autenticação a todas as requisições
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Interceptor para sincronizar dados offline
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (!error.response && error.config.method === 'get') {
      // Tenta obter dados do IndexedDB caso esteja offline
      if (error.config.url?.includes('/componentes') && error.config.params) {
        const usuarioId = error.config.params.usuarioId;
        const baralhoId = error.config.params.baralhoId;
        return await getComponentesRevisao(usuarioId, baralhoId);
      }
    } else if (error.response.status === 401) {
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

// Lógica para sincronizar dados offline ao reconectar
export async function syncDataIfNeeded(usuarioId:number, cursoId:number) {
  if (navigator.onLine) {
    try {
      const offlineData = await getComponentesRevisao(); // Busca dados do IndexedDB
      await syncWithServer(offlineData, usuarioId, cursoId); // Envia ao servidor
      console.log("Dados sincronizados com o servidor.");
    } catch (error) {
      console.error("Erro ao sincronizar dados offline:", error);
    }
  }
}

// Funções para Pastas
export const fetchPastas = () => api.get('/pastas');
export const fetchPastasPorCurso = (cursoId: number) => {
  return api.get(`/pastas?cursoId=${cursoId}`);
};
export const createPasta = (nome: string, parentId?: number, cursoId?: number) => {
  return api.post('/pastas', { nome, pasta_pai_id: parentId, curso_id: cursoId });
};
export const deletePasta = (id: number) => api.delete(`/pastas/${id}`);
export const updatePasta = (id: number, nome: string) => api.put(`/pastas/${id}`, { nome });

// Funções para Páginas
export const fetchPaginas = () => api.get('/paginas');
export const createPagina = (titulo: string, pastaId: number, cursoId: number) => {
  return api.post('/paginas', { titulo, tipoPagina: 'generica', pasta_id: pastaId, curso_id: cursoId });
};
export const deletePagina = (id: number) => api.delete(`/paginas/${id}`);
export const updatePagina = (id: number, titulo: string) => api.put(`/paginas/${id}`, { titulo });
export const updateComponenteOrder = async (pageId: number, componentes: { id: number; ordem: number }[]) => {
  return api.put(`/paginas/${pageId}/updateOrder`, { pageId, componentes });
};

// Funções para Componentes
export const fetchComponentes = (paginaId: number) => api.get(`/componentes/${paginaId}`);
export const createComponente = async (type: string, dados: any, pagina_id: number) => {
  const response = await api.post('/componentes', { type, dados, pagina_id });
  return response;
};
export const createComponenteExemploOffline = async (type: string, dados: any, pagina_id: number) => {
  const response = await api.post('/componentes', { type, dados, pagina_id });
  if (response.status === 200) {
    await saveComponenteRevisao({ 
      id: response.data.id,
      type,
      dados,
      usuarioId: dados.usuarioId,
      baralhoId: dados.baralhoId,
      cursoId: dados.cursoId,
    });
  }
  return response;
};

export const deleteComponente = (id: number) => api.delete(`/componentes/${id}`);
export const updateComponente = (id: number, dados: any) => api.put(`/componentes/${id}`, { dados });

// Funções para atualizar ordem dos componentes
export const updateComponentOrder = (componentes: { id: number; ordem: number }[]) => api.put('/componentes/updateOrder', { componentes });

// Funções para autenticação
export const login = (email: string, senha: string) => api.post('/auth/login', { email, senha });
export const register = (nome: string, email: string, senha: string) => api.post('/auth/register', { nome, email, senha });
export const socialLogin = (provider: string, token: string) => api.post(`/auth/${provider}/callback`, { token });

// Funções para Revisão
export const fetchRevisoes = (usuarioId: number, tipo: 'espacada' | 'periodica') => {
  return api.get(`/revisoes/${usuarioId}?tipo=${tipo}`);
};

export const createRevisaoEspacada = (usuarioId: number, componenteId: number) => api.post('/revisoes/espacada', { usuarioId, componenteId });
export const createRevisaoPeriodica = (usuarioId: number, componenteId: number) => api.post('/revisoes/periodica', { usuarioId, componenteId });
export const registrarSessaoRevisaoEspacada = (usuarioId: number, itemId: number, resposta: string, sucesso: boolean) => api.post('/revisoes/espacada/sessao', { usuarioId, itemId, resposta, sucesso });
export const registrarSessaoRevisaoPeriodica = (usuarioId: number, itemId: number) => api.post('/revisoes/periodica/sessao', { usuarioId, itemId });

export const fetchComponentesParaRevisao = (baralhoIds: string, usuarioId: number) => {
  return api.get(`/revisoes/componentes/teste`, {
    params: {
      baralhoIds,  // Envia como string de IDs separados por vírgulas
      usuarioId,
    },
  });
};



export const registrarRespostaRevisao = (componenteId: number, resposta: string, tipo: 'espacada' | 'periodica') => {
  return api.post(`/revisoes/responder`, { componenteId, resposta, tipo });
};

// Funções para atualizar e-mail e senha do usuário
export const updateEmail = (id: number, email: string) => api.put(`/usuarios/${id}/email`, { email });
export const updateSenha = (id: number, senha: string) => api.put(`/usuarios/${id}/senha`, { senha });

// Funções para Baralhos
export const fetchBaralhos = () => api.get(`/baralhos`);
export const createBaralho = (cursoId: number, nome: string, baralhoPaiId?: number) => {
  return api.post('/baralhos', { curso_id: cursoId, nome, baralho_pai_id: baralhoPaiId });
};
export const updateBaralho = (baralhoId: number, nome: string) => api.put(`/baralhos/${baralhoId}`, { nome });
export const deleteBaralho = (baralhoId: number) => api.delete(`/baralhos/${baralhoId}`);
export const getBaralhoDeleteInfo = (baralhoId: number) => api.get(`/baralhos/${baralhoId}/delete-info`);

export const fetchBaralhosPorCurso = (cursoId: number) => {
  return api.get(`/baralhos?cursoId=${cursoId}`);
};


// Funções para Componentes e Itens de Revisão
export const createComponenteRevisao = async (usuarioId:number, type: string, dados: any, baralhoId: number) => {
  try {
    return await api.post('/componentes/revisao', {usuarioId, type, dados, baralho_id: baralhoId });
  } catch (error) {
    console.error('Error creating revision component:', error);
    throw error;
  }
};

export const addComponentesPaginaRevisao = async (usuarioId: number, componentesIds: number[], baralhoId: number) => {
  try {
    return await api.post('/componentes/revisao/pagina', {
      usuarioId,
      componentesIds, // Note que estamos enviando um array de IDs
      baralho_id: baralhoId
    });
  } catch (error) {
    console.error('Error adding components for revision:', error);
    throw error;
  }
};


export const createItemRevisaoEspacada = async (usuarioId: number, componenteId: number, baralhoId: number) => {
  try {
    return await api.post('/revisoes/espacada', { usuarioId, componenteId, baralhoId });
  } catch (error) {
    console.error('Error creating spaced repetition item:', error);
    throw error;
  }
};

export const fetchItensRevisaoEspacada = () => api.get('/itensRevisaoEspacada');

// Função para buscar itens de revisão espaçada por baralho
export const fetchItensRevisaoEspacadaByBaralho = (baralhoId: number, usuarioId: number) => {
  return api.get(`/itensRevisaoEspacada/${baralhoId}?usuarioId=${usuarioId}`);
};

// Função para buscar itens de revisão espaçada por baralho
export const fetchItensRevisaoEspacadaByCurso = (cursoId: number, usuarioId: number) => {
  return api.get(`/itensRevisaoEspacada/porcurso/${cursoId}?usuarioId=${usuarioId}`);
};

export const fetchItensRevisaoEspacadaByBaralhos = (baralhoIds: number[], usuarioId: number) => {
  return api.post(`/itensRevisaoEspacada/multiple?usuarioId=${usuarioId}`, { baralhoIds });
};


// Função para criar componentes e associá-los aos baralhos
export const createComponenteBaralho = (baralhoId: number, type: string, dados: any) => {
  return api.post('/componentesBaralho', { baralho_id: baralhoId, type, dados });
};


// Função para buscar todos os componentes de um baralho específico
export const fetchComponentesByBaralho = (baralhoId: number) => {
  return api.get(`/baralhos/${baralhoId}/componentes`);
};

// Funções para Cursos
export const fetchCursos = () => {
  return api.get('/cursos/meus-cursos');
};


// src/services/api.ts

// Funções para Estatísticas
export const fetchEstatisticas = (usuarioId: number, cursoId:number, dias: number) => {
  return api.get(`/estatisticas/${usuarioId}?cursoId=${cursoId}&dias=${dias}`);
};

export const fetchItensPorBaralho = (usuarioId: number) => {
  return api.get(`/estatisticas/baralhos/${usuarioId}`);
};

// Funções para Cursos
export const fetchTodosCursos = () => api.get('/cursos');
export const createCurso = (dados: { nome: string }) => api.post('/cursos', dados);
export const updateCurso = (cursoId: number, dados: { nome: string }) => api.put(`/cursos/${cursoId}`, dados);
export const deleteCurso = (cursoId: number) => api.delete(`/cursos/${cursoId}`);

// Funções para Vincular Usuários
export const fetchUsuarios = () => api.get('/usuarios');


export const fetchUsuariosPorCurso = (cursoId: number) => api.get(`/cursos/${cursoId}/usuarios`);
export const vincularUsuarioAoCurso = (cursoId: number, usuariosIds: number[]) => {
  return api.post(`/cursos/${cursoId}/vincular-usuario`, { usuariosIds });
};

export const vincularUsuariosAoCurso = (cursoId: number, usuariosIds: number[]) => {
  return api.post(`/cursos/${cursoId}/vincular-usuarios`, { usuariosIds });
};

export const updateTipoUsuario = (cursoId: number, usuarioId: number, tipoUsuario: 'aluno' | 'professor' | 'admin') => {
  return api.put(`/usuarios-cursos/${cursoId}/tipo-usuario/${usuarioId}`, { tipoUsuario });
};


export const desvincularUsuarioDoCurso = (cursoId: number, usuarioId: number) => {
  return api.delete(`/cursos/${cursoId}/desvincular-usuario/${usuarioId}`);
};

export const fetchTipoUsuarioNoCurso = (cursoId: number, usuarioId: number) => {
  return api.get(`/usuarios-cursos/${cursoId}/tipo-usuario/${usuarioId}`);
};

// Função para buscar todos os componentes de uma página específica
export const fetchComponentesPorPagina = (paginaId: number) => {
  return api.get(`/componentes/pagina/${paginaId}`);
};



export default api;
