// LoadingSpinner.tsx
import React from 'react';
import './LoadingSpinner.css'; // Adicione um estilo para o spinner

const LoadingSpinner = () => (
    <div className="loading-spinner">
        <div className="spinner"></div>
        <p>Carregando...</p>
    </div>
);

export default LoadingSpinner;
