// src/services/indexedDBService.ts
import { openDB, DBSchema } from 'idb';
import { createComponente, deleteComponente, updateBaralho, createBaralho } from './api';

interface MemoNowDB extends DBSchema {
  componentesRevisao: {
    key: number;
    value: {
      id: number;
      type: string;
      dados: any;
      usuarioId: number;
      cursoId: number;
      baralhoId?: number;
      updatedAt: Date;
    };
    indexes: { 
      'by-usuario-baralho': [number, number];
      'by-usuario-curso': [number, number];
    };
  };
  offlineChanges: {
    key: number;
    value: {
      id?: number;
      type: string;
      action: 'add' | 'delete' | 'rename' | 'add-sub';
      data: any;
    };
  };
}

const dbPromise = openDB<MemoNowDB>('memoNowDB', 1, {
  upgrade(db) {
    const store = db.createObjectStore('componentesRevisao', {
      keyPath: 'id',
      autoIncrement: true,
    });
    store.createIndex('by-usuario-baralho', ['usuarioId', 'baralhoId']);
    store.createIndex('by-usuario-curso', ['usuarioId', 'cursoId']); // Novo índice para busca por curso
    db.createObjectStore('offlineChanges', { keyPath: 'id', autoIncrement: true });
  },
});

// Função para salvar um componente de revisão
export async function saveComponenteRevisao(componente: {
  id: number;
  type: string;
  dados: any;
  usuarioId: number;
  cursoId: number;
  baralhoId?: number;
}) {
  const db = await dbPromise;
  await db.put('componentesRevisao', {
    ...componente,
    updatedAt: new Date(),
  });
}

// Função para obter componentes de revisão por baralho
export async function getComponentesRevisaoPorBaralho(usuarioId?: number, baralhoId?: number) {
  const db = await dbPromise;
  if (usuarioId && baralhoId) {
    return db.getAllFromIndex('componentesRevisao', 'by-usuario-baralho', [usuarioId, baralhoId]);
  } else {
    return db.getAll('componentesRevisao'); // Retorna todos os dados se os IDs não forem fornecidos
  }
}

// Função para obter componentes de revisão por curso
export async function getComponentesRevisao(usuarioId?: number, cursoId?: number) {
  const db = await dbPromise;
  if (usuarioId && cursoId) {
    return db.getAllFromIndex('componentesRevisao', 'by-usuario-curso', [usuarioId, cursoId]);
  } else {
    return db.getAll('componentesRevisao'); // Retorna todos os dados se os IDs não forem fornecidos
  }
}

// Sincronizar dados com o servidor
export async function syncWithServer(baralhosData: any[], usuarioId:number, cursoId:number) {
  const db = await dbPromise;
  const tx = db.transaction('componentesRevisao', 'readwrite');
  const store = tx.objectStore('componentesRevisao');

  for (const item of baralhosData) {
    const localItem = await store.get(item.id);
    console.log("Sincronizando item:", item); // Log para confirmar dados
    if (!localItem || new Date(item.updatedAt) > new Date(localItem.updatedAt)) {
      store.put({ ...item, usuarioId, cursoId});
    }
  }

  await tx.done;
}

// Salva uma alteração offline para sincronizar depois
export async function saveOfflineChange(change: {
  type: string;
  action: 'add' | 'delete' | 'rename' | 'add-sub';
  data: any;
}) {
  const db = await dbPromise;
  await db.add('offlineChanges', change);
}

// Obtém todas as alterações offline para sincronização
export async function getOfflineChanges() {
  const db = await dbPromise;
  return db.getAll('offlineChanges');
}

// Limpa as alterações offline após a sincronização
export async function clearOfflineChanges() {
  const db = await dbPromise;
  const tx = db.transaction('offlineChanges', 'readwrite');
  tx.objectStore('offlineChanges').clear();
  await tx.done;
}

// Sincronizar dados offline se necessário
export async function syncDataIfNeeded() {
  const db = await dbPromise;
  const offlineChanges = await getOfflineChanges();

  for (const change of offlineChanges) {
    try {
      if (change.action === 'add') {
        await createComponente(change.type, change.data, change.data.usuarioId);
      } else if (change.action === 'delete') {
        await deleteComponente(change.data.id);
      } else if (change.action === 'rename') {
        await updateBaralho(change.data.baralhoId, change.data.novoNome);
      } else if (change.action === 'add-sub') {
        await createBaralho(change.data.cursoId, change.data.nome, change.data.parentId);
      }

      // Remove o item do IndexedDB após sincronizar com sucesso
      await db.delete('offlineChanges', change.id);
    } catch (error) {
      console.error("Erro ao sincronizar mudança offline:", error);
    }
  }
}
